import { toast } from 'react-toastify';
import { ApiException, ErrorResponseModel } from '../swagger/putujmoAdminService';
import { RouteEnum, routes } from '../settings/routes';

export type BaseResponseModel = {
	success: boolean
	errors?: any
	response?: any
}

export const tryCatchJsonByAction = async (fetchFunction: (...args: any[]) => Promise<any | ErrorResponseModel>): Promise<BaseResponseModel> => {
	const fetchPromise = fetchFunction();	

	try {
		const response = await fetchPromise;
		return {success: true, response};
	}
	catch (error: any) {
		// server error
		if (error.status === 500) {
			toast.error("Nepoznata greška. Molimo vas kontaktirajte administratora");
			return {success: false};
		}

		// validation error
		if (error.status === 400) {
			const errorResponse = (error as ApiException).result;
			if (errorResponse.errors['']) {
				toast.error(errorResponse.errors['']);
			}
			return {success: false, errors: errorResponse.errors};
			
		}

		// forbidden, there is no permission for this user or action is not possible (example: cannot delete entity that is used)
		if (error.status === 403) {
			toast.error('Nemate prava da izvršite operaciju');
			return {success: false};
		}

		// unauthorized error
		if (error.status === 401) {
			localStorage.clear();
			window.location.pathname = routes[RouteEnum.Login];

			toast.error('Vaša sesija je istekla. Molimo vas da se prijavite ponovo');
		}

		toast.error("Nepoznata greška. Molimo vas kontaktirajte administratora");
		return {success: false};
	}
}
