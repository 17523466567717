import { InsertArticleRequest, ArticleClient, PutujmoSite, ArticleType, UpdateArticleRequest } from '../../swagger/putujmoAdminService';

export const getAllArticlesAction = (putujmoSite: PutujmoSite | undefined, articleType: ArticleType | undefined, language: string | undefined) => {
	const client = new ArticleClient();
	return client.getAll(putujmoSite, articleType, language);
}

export const getArticleAction = (id: number) => {
	const client = new ArticleClient();
	return client.get(id);
}

export const createArticleAction = (article: InsertArticleRequest) => {
	const client = new ArticleClient();
	return client.insert(article);
}

export const updateArticleAction = (article: UpdateArticleRequest) => {
	const client = new ArticleClient();
	return client.update(article);
}

export const deleteArticleAction = (id: number) => {
	const client = new ArticleClient();
	return client.remove(id);
}