import { useMemo } from 'react';
import { ArticleResponse, ArticleType } from '../../../swagger/putujmoAdminService';
import { propertyOf } from '../../../utils/propertyOf';
import { formatDate } from '../../../utils/dateUtils';
import { ColumnDefinition } from 'react-tabulator';

export const useTableColumnsMemo = (articleType: ArticleType) => {

	const columns: ColumnDefinition[] = useMemo(
		() => {
			let result =  [
				{
					title:'Naslov', 
					field: propertyOf<ArticleResponse>('title')
				},

			]

			if (articleType === ArticleType.Blog || articleType === ArticleType.HistoricalHeritage) {
				result = [...result,
					{
						title:'Autor', 
						field: propertyOf<ArticleResponse>('author')
					},
				]
			} else if (articleType === ArticleType.RealEstate) {
				result = [...result,
					{
						title:'Investitor', 
						field: propertyOf<ArticleResponse>('investor')
					},
					{
						title:'Lokacija', 
						field: propertyOf<ArticleResponse>('location')
					},
					{
						title:'Telefon', 
						field: propertyOf<ArticleResponse>('phone')
					},
					{
						title:'Email', 
						field: propertyOf<ArticleResponse>('email')
					},
					{
						title:'Web sajt', 
						field: propertyOf<ArticleResponse>('website')
					},
				]
			} else if (articleType === ArticleType.Authenticity) {
				result = [...result,
					{
						title:'Lokacija', 
						field: propertyOf<ArticleResponse>('location')
					},
					{
						title:'Telefon', 
						field: propertyOf<ArticleResponse>('phone')
					},
					{
						title:'Email', 
						field: propertyOf<ArticleResponse>('email')
					},
					{
						title:'Adresa', 
						field: propertyOf<ArticleResponse>('address')
					},
					{
						title:'Web sajt', 
						field: propertyOf<ArticleResponse>('website')
					},
				]
			}

			return [...result, 
			{
				title: 'Poslednja promena',
				field: propertyOf<ArticleResponse>('updatedAt'),
				formatter: (cell: any) => formatDate(cell.getValue())
			}];
		},
		[articleType]
	)

	return columns;
}