import { propertyOf } from '../../../utils/propertyOf'
import { CrudRouter, ParamType } from '../../Routers/CrudRouter'
import { PhobsPropertiesTable } from '../Table/PhobsPropertiesTable'


export const WithoutPricesPhobsPropertiesRouter = () => {
	return (
		<CrudRouter
			ListComponent={PhobsPropertiesTable}
			additionalData={{active: false}}
			paramName={propertyOf<ParamType>('id')}
		/>
	)
}