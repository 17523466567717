import {  ArticleResponse, UpdateArticleRequest } from '../../../swagger/putujmoAdminService';
import { CrudEnum } from '../../../settings/routes';
import {  ArticleType } from '../../../swagger/putujmoAdminService';
import { HistoricalHeritageForm } from './HistoricalHeritageForm';
import { BlogForm } from './BlogForm';
import { RealEstateForm } from './RealEstateForm';
import { AuthenticityForm } from './AuthenticityForm';

type Props = {
	values?: ArticleResponse
	onSubmit?: (newValues: UpdateArticleRequest) => Promise<void>
	onCancel?: () => void
	crudEnum: CrudEnum
	articleType: ArticleType
}

export const ArticleForm = ({values, onSubmit, onCancel, crudEnum, articleType}: Props) => {
	switch (articleType) {
		case ArticleType.Blog:
			return (
				<BlogForm
					values={values}
					onSubmit={onSubmit}
					onCancel={onCancel}
					crudEnum={crudEnum}
				/>
			)
		case ArticleType.HistoricalHeritage:
			return (
				<HistoricalHeritageForm
					values={values}
					onSubmit={onSubmit}
					onCancel={onCancel}
					crudEnum={crudEnum}
				/>
			)
		case ArticleType.RealEstate:
			return (
				<RealEstateForm
					values={values}
					onSubmit={onSubmit}
					onCancel={onCancel}
					crudEnum={crudEnum}
				/>
			)
		case ArticleType.Authenticity:
		default:
			return (
				<AuthenticityForm
					values={values}
					onSubmit={onSubmit}
					onCancel={onCancel}
					crudEnum={crudEnum}
				/>
			)
	}
}