import { ArticleType } from '../../../../swagger/putujmoAdminService'
import { propertyOf } from '../../../../utils/propertyOf'
import { CrudRouter, ParamType } from '../../../Routers/CrudRouter'
import { CreateArticle } from '../../Crud/CreateArticle'
import { ReadArticle } from '../../Crud/ReadArticle'
import { UpdateArticle } from '../../Crud/UpdateArticle'
import { ArticleTable } from '../../Table/ArticleTable'

export const HistoricalHeritageRouter = () => {
	return (
		<CrudRouter
			ListComponent={ArticleTable}
			ReadComponent={ReadArticle}
			UpdateComponent={UpdateArticle}
			CreateComponent={CreateArticle}
			additionalData={{articleType: ArticleType.HistoricalHeritage}}
			paramName={propertyOf<ParamType>('id')}
		/>
	)
}