import { useCallback } from 'react';
import { TabType } from './Tabs';


type Props = {
	tab: TabType
	isActive: boolean
	onClick(tab: TabType): void
}

export const Tab = ({ tab, isActive, onClick }: Props) => {
	const onClickCallback = useCallback(
		() => {
			!tab.disabled && onClick(tab);
		},
		[tab, onClick]
	)

	return (
		<div
			className={`tab ${isActive ? 'active' : ''} ${tab.disabled ? 'disabled' : ''}`}
			onClick={onClickCallback}
		>
			{tab.title}
		</div>
	)
}
