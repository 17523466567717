import { InsertCategoryRequest, CategoryClient, PutujmoSite, UpdateCategoryRequest, ArticleType } from '../../swagger/putujmoAdminService';

export const getAllCategoriesAction = (putujmoSite: PutujmoSite | undefined, articleType: ArticleType | undefined, language: string | undefined) => {
	const client = new CategoryClient();
	return client.getAll(putujmoSite, articleType, language);
}

export const getCategoryAction = (id: number) => {
	const client = new CategoryClient();
	return client.get(id);
}

export const createCategoryAction = (request: InsertCategoryRequest) => {
	const client = new CategoryClient();
	return client.insert(request);
}

export const updateCategoryAction = (request: UpdateCategoryRequest) => {
	const client = new CategoryClient();
	return client.update(request);
}

export const deleteCategoryAction = (id: number) => {
	const client = new CategoryClient();
	return client.remove(id);
}