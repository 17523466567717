import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

const getGoBackRelativePath = (initialPath: string, level: number) => {
	let path = initialPath;
	if (level > 1) {
		for (let i = 1; i < level; i++) {
			path += '../';
		}
	}

	return path;
}

// this is better way, instead of history.goBack(), because someone maybe copy/pasted URL which has 'Back' button on it
// but problem is that URL can end with:
// /Read/2
// /Create
// and './' is not good enough, so optional level is added

export const useGoBackCallback = () => {
	const history = useHistory();

	return useCallback(
		(level: number = 1) => {
			const path = getGoBackRelativePath(history.location.pathname.endsWith('/') ? '../' : './', level);
            history.push(path, history.location.state);
		},
		[history]
	)
}

export const useGoBackAndPushCallback = () => {
	const history = useHistory();

	return useCallback(
		(level: number = 1, toPush: string) => {
			const path = getGoBackRelativePath(history.location.pathname.endsWith('/') ? '../' : './', level);
            history.push(`${path}${toPush.startsWith('/') ? toPush.substring(1) : toPush}`, history.location.state);
		},
		[history]
	)
}
