import { useCallback, useState } from 'react';
import { InsertManagementUserRequest, ManagementUserResponse, UserRoleEnum } from '../../swagger/putujmoAdminService';
import { CrudEnum } from '../../settings/routes';
import { Input } from '../../components/Input/Input';
import { Button } from '../../components/Button/Button';
import { MultiSelect } from '../../components/MultiSelect/MultiSelect';

type Props = {
	values?: ManagementUserResponse
	onSubmit?: (newValues: InsertManagementUserRequest) => Promise<void>
	onCancel?: () => void
	crudEnum: CrudEnum
}


export const UserForm = ({values, onSubmit, onCancel, crudEnum}: Props) => {
	const [email, setEmail] = useState(values?.userEmail || '');
	const [password, setPassword] = useState('');
	const [firstName, setFirstName] = useState(values?.firstName || '');
	const [lastName, setLastName] = useState(values?.lastName || '');
	const [role, setRole] = useState(values?.role || UserRoleEnum.Admin);

	const submitCallback = useCallback(
		async (e: any) => {
			e.preventDefault();
			const model = new InsertManagementUserRequest();
			model.email = email;
			model.firstName = firstName;
			model.lastName = lastName;
			model.visiblePassword = password;
			model.role = role;
			onSubmit && await onSubmit(model);
		},
		[
			email,
			firstName,
			lastName,
			password,
			role,
			onSubmit
		]
	)

	const isRead = crudEnum === CrudEnum.Read;

	return (
		<form onSubmit={submitCallback}>
			<Input
				label='Email'
				value={email}
				onChange={(value) => setEmail(value)}
				disabled={isRead}
			/>
			{!isRead && 
				<Input
					label='Lozinka'
					value={password}
					onChange={(value) => setPassword(value)}
				/>
			}
			<Input
				label='Ime'
				value={firstName}
				onChange={(value) => setFirstName(value)}
				disabled={isRead}
			/>
			<Input
				label='Prezime'
				value={lastName}
				onChange={(value) => setLastName(value)}
				disabled={isRead}
			/>
			<MultiSelect
				label='Rola'
				value={[role]}
				items={[
					{ id: UserRoleEnum.Admin, text: 'Administrator' },
					{ id: UserRoleEnum.Operator, text: 'Operater' }
				]}
				onChange={(value) => {
					setRole(value.length > 0 ? value[0] : UserRoleEnum.Admin)
				}}
				getItemId={(item: any) => item.id}
				getItemText={(item: any) => item.text}
				disabled={isRead}
			/>
			<div className='flex-end-container'>
				{!isRead && (
					<Button
						type='submit'
						value='Prihvati'
						disabled={
							!email || 
							!firstName || 
							!lastName ||
							!password
						}
					/>
				)}
				{!isRead && (
					<Button
						type='button'
						value='Odustani'
						onClick={onCancel}
						outline
					/>
				)}
			</div>
		</form>
	)
}