import { useMemo } from 'react';
import { propertyOf } from '../../../utils/propertyOf';
import { ColumnDefinition } from 'react-tabulator';

export type SimpleProperty = {
	id: string
	name: string
	city: string
}

export const useTableColumnsMemo = () => {
	const columns: ColumnDefinition[] = useMemo(
		() => {
			return [
				{
					title: '',
					rowHandle:true,
					formatter:"handle",
					headerSort:false,
					frozen:true,
					width:30,
					minWidth:30
				},
				{
					title:'Id objekta', 
					field: propertyOf<SimpleProperty>('id'),
				},
				{
					title:'Naziv', 
					field: propertyOf<SimpleProperty>('name'),
				},
				{
					title:'Mesto', 
					field: propertyOf<SimpleProperty>('city'),
				}
			]
		},
		[]
	)
	return columns;
}