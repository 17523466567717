import { useMemo } from 'react';
import { Dialog } from '../Dialog';
import { Button } from '../../Button/Button';

type Props = {
	title: string
	open: boolean
	message: string
	onConfirm(): void
	onCancel(): void
}

export const SimpleDialog = ({ title, open, message, onConfirm, onCancel }: Props) => {
	const content = useMemo(
		() => {
			return (
				<>
					<div className='simple-dialog-content'>
						{message}
					</div>
					<div className='flex-end-container'>
						<Button
							value='Da'
							onClick={onConfirm}
						/>
						<Button
							value='Ne'
							onClick={onCancel}
							outline
						/>
					</div>
				</>
			)
		},
		[message, onCancel, onConfirm]
	)

	return (
		<Dialog 
			title={title}
			open={open}
			onClose={onCancel}
			children={content}
		/>
	)
}
