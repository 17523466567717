export enum RouteEnum {
	Home = 'home',
	Login = 'login',
	RegistrationConfirm = 'registration-confirm',
	Blogs = 'blogs',
	HistoricalHeritage = 'historical-heritage',
	RealEstate = 'real-estate',
	Authenticity = 'authenticity-countries',
	Categories = 'categories-tabs',
	User = 'user',
	PhobsProperties = 'phobs-properties',
}

export const routes = {
	[RouteEnum.Home]: '/',
	[RouteEnum.Login]: `/${RouteEnum.Login}`,
	[RouteEnum.RegistrationConfirm]: `/${RouteEnum.RegistrationConfirm}`,
	[RouteEnum.Blogs]: `/${RouteEnum.Blogs}`,
	[RouteEnum.HistoricalHeritage]: `/${RouteEnum.HistoricalHeritage}`,
	[RouteEnum.RealEstate]: `/${RouteEnum.RealEstate}`,
	[RouteEnum.Authenticity]: `/${RouteEnum.Authenticity}`,
	[RouteEnum.Categories]: `/${RouteEnum.Categories}`,
	[RouteEnum.User]: `/${RouteEnum.User}`,
	[RouteEnum.PhobsProperties]: `/${RouteEnum.PhobsProperties}`,
}

export enum CrudEnum {
	Create = 'create',
	Read = 'read',
	Update = 'update'
}