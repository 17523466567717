import { useCallback, useState } from 'react';
import { ArticleType, CategoryResponse, UpdateCategoryRequest } from '../../swagger/putujmoAdminService';
import { CrudEnum } from '../../settings/routes';
import { Input } from '../../components/Input/Input';
import { Button } from '../../components/Button/Button';
import { MultiSelect } from '../../components/MultiSelect/MultiSelect';
import { PutujmoSite } from '../../swagger/putujmoAdminService';
import { useGoBackFromCrudAndPush } from '../../utils/crudHooks';
import { LanguageEnum, languageOptions, siteOptions } from '../../utils/languageUtils';

type Props = {
	values?: CategoryResponse
	onSubmit?: (newValues: UpdateCategoryRequest) => Promise<void>
	onCancel?: () => void
	crudEnum: CrudEnum
	articleType: ArticleType
}


export const CategoryForm = ({values, onSubmit, onCancel, crudEnum, articleType}: Props) => {
	const [putujmoSite, setPutujmoSite] = useState(values?.putujmoSite || PutujmoSite.Serbia);
	const [language, setLanguage] = useState(values?.language as LanguageEnum || LanguageEnum.Serbian);
	const [name, setName] = useState(values?.name || '');

	const submitCallback = useCallback(
		async (e: any) => {
			e.preventDefault();
			const model = new UpdateCategoryRequest();
			model.putujmoSite = putujmoSite;
			model.language = language;
			model.articleType = articleType;
			model.name = name;
			onSubmit && await onSubmit(model);
		},
		[
			putujmoSite,
			language,
			name,
			articleType,
			onSubmit
		]
	)

	const isRead = crudEnum === CrudEnum.Read;

	const goBackFromCrudAndPush = useGoBackFromCrudAndPush(CrudEnum.Read, undefined, undefined);

	const goUpdateCallback = useCallback(
		() => {
			if (values && values.id) {
				goBackFromCrudAndPush(`${CrudEnum.Update}/${values?.id}`);
			}
		},
		[values, goBackFromCrudAndPush]
	)

	return (
		<form onSubmit={submitCallback}>
			<MultiSelect
				label='Sajt'
				value={[putujmoSite]}
				items={siteOptions}
				onChange={(value) => {
					setPutujmoSite(value.length > 0 ? value[0] : PutujmoSite.Serbia)
				}}
				getItemId={(item: any) => item.id}
				getItemText={(item: any) => item.text}
				disabled={isRead}
			/>
			<MultiSelect
				label='Jezik'
				value={[language]}
				items={languageOptions}
				onChange={(value) => {
					setLanguage(value.length > 0 ? value[0] : LanguageEnum.Serbian)
				}}
				getItemId={(item: any) => item.id}
				getItemText={(item: any) => item.text}
				disabled={isRead}
			/>
			<Input
				label='Naziv'
				value={name}
				onChange={(value) => setName(value)}
				disabled={isRead}
			/>
			<div className='flex-end-container'>
				{!isRead && (
					<Button
						type='submit'
						value='Prihvati'
						disabled={
							!putujmoSite || 
							!name ||
							!language
						}
					/>
				)}
				{!isRead && (
					<Button
						type='button'
						value='Odustani'
						onClick={onCancel}
						outline
					/>
				)}
				{isRead && (
					<Button
						type='button'
						value='Izmeni'
						onClick={goUpdateCallback}
					/>
				)}
			</div>
		</form>
	)
}