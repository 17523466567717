import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ParamType, ReadComponentProps } from '../../Routers/CrudRouter';
import { useGoBackFromRead } from '../../../utils/crudHooks';
import { tryCatchJsonByAction } from '../../../utils/fetchUtils';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { Dialog } from '../../../components/Dialog/Dialog';
import { CrudEnum } from '../../../settings/routes';
import { CategoryResponse } from '../../../swagger/putujmoAdminService';
import { getCategoryAction } from '../../../features/categories/action';
import { CategoryForm } from '../CategoryForm';

export const ReadCategory = ({additionalData}: ReadComponentProps) => {
	const params: ParamType = useParams();
	const id = parseInt(params.id as string);

	const [loading, setLoading] = useState(true);
	const [data, setData] = useState(new CategoryResponse());
	const goBackFromRead = useGoBackFromRead();

	const fetchDataCallback = useCallback(
		async () => {
			setLoading(true);
			const bindedAction = getCategoryAction.bind(null, id)
			var getResponse = await tryCatchJsonByAction(bindedAction);
			if (getResponse.success) {
				setData(getResponse.response || new CategoryResponse())
			}
			setLoading(false);
		},
		[id]
	)

	useEffect(
		() => {
			fetchDataCallback();
		},
		[fetchDataCallback]
	)


	if (loading) {
		return <LoadingSpinner />
	}

	return (
		<Dialog
			title='Detalji'
			onClose={goBackFromRead}
			open
		>
			<CategoryForm
				values={data}
				crudEnum={CrudEnum.Read}
				articleType={additionalData.articleType}
			/>
		</Dialog>
	)
}
