import FileSaver from 'file-saver';

export const downloadImage = async (imageUrl: string) => {
    const response = await fetch(imageUrl, {
      method: "GET",
      headers: {}
    })

	const blob = await response.blob();
	var filename = imageUrl.split('\\')!.pop()!.split('/').pop();
	FileSaver.saveAs(blob, filename);
};