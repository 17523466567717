import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { CreateComponentProps } from '../../Routers/CrudRouter';
import { useGoBackFromCreate } from '../../../utils/crudHooks';
import { InsertArticleRequest } from '../../../swagger/putujmoAdminService';
import { tryCatchJsonByAction } from '../../../utils/fetchUtils';
import { Dialog } from '../../../components/Dialog/Dialog';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { CrudEnum } from '../../../settings/routes';
import { ArticleForm } from '../Forms/ArticleForm';
import { createArticleAction } from '../../../features/articles/action';

export const CreateArticle = ({ publishDataChanged, additionalData }: CreateComponentProps) => {
	const [loading, setLoading] = useState(false);
	const goBackFromCreate = useGoBackFromCreate();

	const handleSave = useCallback(
		async (newValues: InsertArticleRequest) => {
			setLoading(true);
			const bindedAction = createArticleAction.bind(null, newValues);
			const response = await tryCatchJsonByAction(bindedAction);
			setLoading(false);

			if (response.success) {
				toast.success('Novi element je uspešno kreiran.');
				publishDataChanged();
				goBackFromCreate();
			} 
		},
		[goBackFromCreate, publishDataChanged]
	);

	return (
		<Dialog
			title='Kreiranje novog elementa'
			onClose={goBackFromCreate}
			size='xxxlarge'
			open
		>
			<ArticleForm
				onSubmit={handleSave}
				onCancel={goBackFromCreate}
				crudEnum={CrudEnum.Create}
				articleType={additionalData.articleType}
			/>
			{loading && <LoadingSpinner />}
		</Dialog>
	)
}
