import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { ListComponentProps } from '../../Routers/CrudRouter';
import { SimpleProperty, useTableColumnsMemo } from './tableColumns';
import { tryCatchJsonByAction } from '../../../utils/fetchUtils';
import { TableButtons } from '../../../components/Table/TableButtons';
import { Table } from '../../../components/Table/Table';
import { PutujmoSite, SimplePropertyModel, UpdatePropertyOrdersRequest, UpdateSinglePropertyOrderRequest } from '../../../swagger/putujmoAdminService';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { MultiSelect } from '../../../components/MultiSelect/MultiSelect';
import { LanguageEnum, languageOptions, siteOptions } from '../../../utils/languageUtils';
import { deletePropertyAction, getAllSimplePropertiesAction, updatePropertyOrdersAction } from '../action';
import { Button } from '../../../components/Button/Button';

export const PhobsPropertiesTable = ({dataChangedTopic, publishDataChanged, additionalData}: ListComponentProps) => {
	const [putujmoSite, setPutujmoSite] = useState(PutujmoSite.Serbia);
	const [language, setLanguage] = useState(LanguageEnum.Serbian);
	const [selectedId, setSelectedId] = useState<string | number | undefined>(undefined);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<SimpleProperty[]>([]);
	const [updatedData, setUpdatedData] = useState<SimpleProperty[]>([]);

	const columns = useTableColumnsMemo();

	const fetchDataCallback = useCallback(
		async () => {
			setLoading(true);
			const bindedAction = getAllSimplePropertiesAction.bind(null, putujmoSite, language)
			var getResponse = await tryCatchJsonByAction(bindedAction);
			if (getResponse.success) {
				const items: SimplePropertyModel[] = getResponse.response.filter((x: SimplePropertyModel) => x.hasPrice === additionalData.active) || [];
				setData(items.map(x => JSON.parse(x.content)));
			}
			setLoading(false);
		},
		[putujmoSite, language, additionalData]
	)

	useEffect(
		() => {
			fetchDataCallback();
		},
		[fetchDataCallback]
	)

	useEffect(
		() => {
			const subscription = dataChangedTopic?.subscribe(fetchDataCallback);
			return () => {
				subscription?.unsubscribe();
			}
		},
		[fetchDataCallback, dataChangedTopic]
	)

	const deleteCallback = useCallback(
		async (id: string) => {
			const bindedAction = deletePropertyAction.bind(null, putujmoSite, id,);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				toast.success('Element je uspešno obrisan');
				setSelectedId(undefined);
				publishDataChanged();
			}
		},
		[publishDataChanged, putujmoSite]
	)

	const saveOrderCallback = useCallback(
		async () => {
			setLoading(true);
			const model = new UpdatePropertyOrdersRequest({
				putujmoSite,
				orders: updatedData.map((item, index) => new UpdateSinglePropertyOrderRequest({
					propertyId: item.id,
					order: index + 1
				}))
			});
			const bindedAction = updatePropertyOrdersAction.bind(null, model);
			var response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				toast.success('Redosled je uspešno sačuvan.');
				publishDataChanged();
			} 

			setLoading(false);
		},
		[publishDataChanged, updatedData, putujmoSite]
	)

	return (
		<div>
			<div className='flex-start-container'>
				<div className='m5'>
					<MultiSelect
						value={[putujmoSite]}
						items={siteOptions}
						onChange={(value) => {
							setPutujmoSite(value.length > 0 ? value[0] : PutujmoSite.Serbia)
						}}
						getItemId={(item: any) => item.id}
						getItemText={(item: any) => item.text}
					/>
				</div>
				<div className='m5'>
					<MultiSelect
						value={[language]}
						items={languageOptions}
						onChange={(value) => {
							setLanguage(value.length > 0 ? value[0] : LanguageEnum.Serbian)
						}}
						getItemId={(item: any) => item.id}
						getItemText={(item: any) => item.text}
					/>
				</div>
				<div className='m5'>
					<MultiSelect
						value={[selectedId]}
						items={data}
						onChange={(value) => {
							setSelectedId(value.length > 0 ? value[0] : undefined)
						}}
						getItemId={(item: SimpleProperty) => item.id}
						getItemText={(item: SimpleProperty) => item.name}
					/>
				</div>
			</div>
			<TableButtons
				selectedId={selectedId}
				onDelete={deleteCallback}
				isCreateable={false}
				isChangeable={false}
				labels={{delete: 'Osveži'}}
				deleteDialogTitle='Osvežavanje'
				deleteDialogText='Da li ste sigurni? Imajte na umu da osvežavanje objekta dovodi do njegovog brisanja iz baze podataka. Na "Putujmo" sistemu će se i dalje prikazivati stara verzija objekta, dok u admin panelu objekat privremeno neće biti vidljiv, sve dok ne istekne predviđeni period (maksimalno 3 sata). Nakon toga će sistem automatski poslati novi zahtev na "Phobs" i ažurirati objekat.'
				AdditionalButton={(
					<Button
						onClick={saveOrderCallback}
						value='Sačuvaj redosled'
					/>
				)}
			/>
			<Table
				columns={columns}
				data={data}
				onSelectedChange={setSelectedId}
				onSortChange={setUpdatedData}
				isReorder
			/>
			{loading && <LoadingSpinner />}
		</div>
	)
}
