type Props = {
	title: string | React.ReactElement
	onClose?(): void
}

export const Header = ({ title, onClose }: Props) => {
	return (
		<div className={'dialog-header'}>
			<div className='title'>
				<h2>{title}</h2>
			</div>
			<span className='close' onClick={onClose}>X</span>
		</div>
	);
}
