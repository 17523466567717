import React, { useCallback, useEffect, useRef } from 'react';
import { Header } from './Header';
import { Cover } from '../Cover/Cover';

type Props = {
	title: string | React.ReactElement,
	onClose(): void,
	open: boolean,
	children: any,
	size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge' | 'xxxlarge',
}

const KEY_NAME_ESC = 'Escape';
const KEY_EVENT_TYPE = 'keyup';

export const Dialog = ({ title, onClose, open, children, size = 'medium' }: Props) => {
	const dialogRef = useRef<HTMLDivElement>(null);

	const startAnimationCallback = useCallback(
		() => {
			dialogRef.current?.classList.add('static');
		},
		[]
	);

	const endAnimationCallback = useCallback(
		() => {
			dialogRef.current?.classList.remove('static');
		},
		[]
	)

	useEffect(
		() => {
			const ref = dialogRef.current;
			ref?.addEventListener('animationend', endAnimationCallback, false);

			return () => {
				ref?.removeEventListener('animationend', endAnimationCallback, false);
			}
		},
		[endAnimationCallback]
	)

	const handleEscKey = useCallback(
		(event: any) => {
			if (event.key === KEY_NAME_ESC) {
				onClose();
			}
		},
		[onClose]
	);

	useEffect(
		() => {
			document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false);

			return () => {
				document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false);
			};
		},
		[handleEscKey]
	);

	if (!open) {
		return <></>
	}

	return (
		<div
			className={`dialog-container ${open ? 'show' : ''}`}
			onKeyUp={handleEscKey}
			ref={dialogRef}
		>
			<Cover onClick={startAnimationCallback} />
			<div
				className={`dialog ${`dialog-${size}`}`}
			>
				<Header
					title={title}
					onClose={onClose}
				/>
				<div className='dialog-content'>
						{children}
				</div>
			</div>
		</div>
	)
}
