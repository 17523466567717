import { useCallback, useEffect, useState } from 'react';
import { CategoryResponse, ArticleResponse, UpdateArticleRequest } from '../../../swagger/putujmoAdminService';
import { CrudEnum } from '../../../settings/routes';
import { Input } from '../../../components/Input/Input';
import { Button } from '../../../components/Button/Button';
import { MultiSelect } from '../../../components/MultiSelect/MultiSelect';
import { PutujmoSite, ArticleType } from '../../../swagger/putujmoAdminService';
import { useGoBackFromCrudAndPush } from '../../../utils/crudHooks';
import { LanguageEnum, languageOptions, siteOptions } from '../../../utils/languageUtils';
import { TextEditor } from '../../../components/Editor/TextEditor';
import { getAllCategoriesAction } from '../../../features/categories/action';
import { tryCatchJsonByAction } from '../../../utils/fetchUtils';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';

type Props = {
	values?: ArticleResponse
	onSubmit?: (newValues: UpdateArticleRequest) => Promise<void>
	onCancel?: () => void
	crudEnum: CrudEnum
}

export const BlogForm = ({values, onSubmit, onCancel, crudEnum}: Props) => {
	const [putujmoSite, setPutujmoSite] = useState(values?.putujmoSite || PutujmoSite.Serbia);
	const [language, setLanguage] = useState(values?.language as LanguageEnum || LanguageEnum.Serbian);
	const [title, setTitle] = useState(values?.title || '');
	const [content, setContent] = useState(values?.content || '');
	const [author, setAuthor] = useState(values?.author || '');
	const [imageUrl, setImageUrl] = useState(values?.imageUrl || '');
	const [categoryIds, setCategoryIds] = useState(values?.categoryIds || []);

	const [loading, setLoading] = useState(false);
	const [categories, setCategories] = useState<CategoryResponse[]>([]);

	const submitCallback = useCallback(
		async (e: any) => {
			e.preventDefault();
			const model = new UpdateArticleRequest();
			model.putujmoSite = putujmoSite;
			model.title = title;
			model.content = content;
			model.author = author;
			model.language = language;
			model.imageUrl = imageUrl;
			model.categoryIds = categoryIds;
			model.articleType = ArticleType.Blog;
			onSubmit && await onSubmit(model);
		},
		[
			putujmoSite,
			title,
			content,
			author,
			language,
			imageUrl,
			categoryIds,
			onSubmit
		]
	)

	const fetchDataCallback = useCallback(
		async () => {
			setLoading(true);
			const bindedAction = getAllCategoriesAction.bind(null, putujmoSite, ArticleType.Blog, language)
			var getResponse = await tryCatchJsonByAction(bindedAction);
			if (getResponse.success) {
				const items = getResponse.response || [];
				setCategories(items);
			}
			setLoading(false);
		},
		[putujmoSite, language]
	)

	useEffect(
		() => {
			fetchDataCallback();
		},
		[fetchDataCallback]
	)

	const isRead = crudEnum === CrudEnum.Read;

	const goBackFromCrudAndPush = useGoBackFromCrudAndPush(CrudEnum.Read, undefined, undefined);

	const goUpdateCallback = useCallback(
		() => {
			if (values && values.id) {
				goBackFromCrudAndPush(`${CrudEnum.Update}/${values?.id}`);
			}
		},
		[values, goBackFromCrudAndPush]
	)

	if (loading) {
		return <LoadingSpinner />
	}

	return (
		<form onSubmit={submitCallback}>
			<div className='flex-between-container'>
				<MultiSelect
					label='Sajt'
					value={[putujmoSite]}
					items={siteOptions}
					onChange={(value) => {
						setPutujmoSite(value.length > 0 ? value[0] : PutujmoSite.Serbia)
					}}
					getItemId={(item: any) => item.id}
					getItemText={(item: any) => item.text}
					disabled={isRead}
				/>
				<MultiSelect
					label='Jezik'
					value={[language]}
					items={languageOptions}
					onChange={(value) => {
						setLanguage(value.length > 0 ? value[0] : LanguageEnum.Serbian)
					}}
					getItemId={(item: any) => item.id}
					getItemText={(item: any) => item.text}
					disabled={isRead}
				/>
				<Input
					label='Naslov'
					value={title}
					onChange={(value) => setTitle(value)}
					disabled={isRead}
				/>
				<Input
					label='Autor'
					value={author}
					onChange={(value) => setAuthor(value)}
					disabled={isRead}
				/>
			</div>
			<div className='flex-between-container'>
				<div style={{width: '60%'}}>
					<Input
						label='Url naslovne slike'
						value={imageUrl}
						onChange={(value) => setImageUrl(value)}
						disabled={isRead}
					/>
				</div>
				<div style={{width: '30%'}}>
					<MultiSelect
						label='Kategorija'
						value={categoryIds}
						items={categories}
						onChange={(value) => {
							setCategoryIds(value)
						}}
						getItemId={(item: CategoryResponse) => item.id}
						getItemText={(item: CategoryResponse) => item.name}
						disabled={isRead}
						multiselect
						isClearable
					/>
				</div>
			</div>		
			<TextEditor
				// content rerender component
				value={values?.content || ''}
				onChange={(value) => setContent(value)}
				disabled={isRead}
			/>
			<div className='flex-end-container'>
				{!isRead && (
					<Button
						type='submit'
						value='Prihvati'
						disabled={
							!putujmoSite || 
							!title || 
							!author ||
							!language
						}
					/>
				)}
				{!isRead && (
					<Button
						type='button'
						value='Odustani'
						onClick={onCancel}
						outline
					/>
				)}
				{isRead && (
					<Button
						type='button'
						value='Izmeni'
						onClick={goUpdateCallback}
					/>
				)}
			</div>
		</form>
	)
}