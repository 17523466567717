import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ParamType, ReadComponentProps } from '../../Routers/CrudRouter';
import { useGoBackFromRead } from '../../../utils/crudHooks';
import { tryCatchJsonByAction } from '../../../utils/fetchUtils';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { Dialog } from '../../../components/Dialog/Dialog';
import { CrudEnum } from '../../../settings/routes';
import { ArticleResponse } from '../../../swagger/putujmoAdminService';
import { getArticleAction } from '../../../features/articles/action';
import { ArticleForm } from '../Forms/ArticleForm';

export const ReadArticle = ({additionalData}: ReadComponentProps) => {
	const params: ParamType = useParams();
	const id = parseInt(params.id as string);

	const [loading, setLoading] = useState(true);
	const [data, setData] = useState(new ArticleResponse());
	const goBackFromRead = useGoBackFromRead();

	const fetchDataCallback = useCallback(
		async () => {
			setLoading(true);
			const bindedAction = getArticleAction.bind(null, id)
			var getResponse = await tryCatchJsonByAction(bindedAction);
			if (getResponse.success) {
				setData(getResponse.response || new ArticleResponse())
			}
			setLoading(false);
		},
		[id]
	)

	useEffect(
		() => {
			fetchDataCallback();
		},
		[fetchDataCallback]
	)


	if (loading) {
		return <LoadingSpinner />
	}

	return (
		<Dialog
			title='Detalji'
			onClose={goBackFromRead}
			size='xxxlarge'
			open
		>
			<ArticleForm
				values={data}
				crudEnum={CrudEnum.Read}
				articleType={additionalData.articleType}
			/>
		</Dialog>
	)
}
