import { useMemo } from 'react';

const TOKEN = 'token';
const NAME = 'name';

export const setToken = (token: string) => {
	localStorage.setItem(TOKEN, token);
}

export const getToken = () : string | null => {
	return localStorage.getItem(TOKEN);
}

export const setFirstName = (name: string) => {
	localStorage.setItem(NAME, name);
}

export const getFirstname = () : string | null => {
	return localStorage.getItem(NAME);
}

export const useGetFirstName = () => {
	const result = useMemo(
		() => {
			return getFirstname();;
		},
		[]
	)
	return result;
}
