import { RegistrationClient, PutujmoSite, RegistrationState } from '../../swagger/putujmoAdminService';

export const getAllRegisteredUsersAction = (putujmoSite: PutujmoSite | undefined, state: RegistrationState  | undefined) => {
	const client = new RegistrationClient();
	return client.getAll(putujmoSite, state);
}

export const getRegisteredUserAction = (userId: number) => {
	const client = new RegistrationClient();
	return client.getRegistrationUser(userId);
}

export const updateRegisteredUserStatusAction = (userId: number, status: RegistrationState | undefined) => {
	const client = new RegistrationClient();
	return client.updateStatus(userId, status);
}

export const deleteRegisteredUserAction = (userId: number) => {
	const client = new RegistrationClient();
	return client.deleteRegistrationUser(userId);
}