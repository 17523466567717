type Props = {
	value: string | number | undefined
	label: string
}

export const LabelText = ({label, value}: Props) => {
	return (
		<div className='label-text-container'>
			<div className='label-text'>{label}: </div>
			<div className='label-text-content'>{value}</div>
		</div>

	)
}