import { ChangePasswordRequest, InsertManagementUserRequest, UserClient } from '../../swagger/putujmoAdminService';

export const getAllUsersAction = () => {
	const client = new UserClient();
	return client.getAll();
}

export const getUserAction = (email: string) => {
	const client = new UserClient();
	return client.get(email);
}

export const createUserAction = (userDTO: InsertManagementUserRequest) => {
	const client = new UserClient();
	return client.insert(userDTO);
}

export const deleteUserAction = (email: string) => {
	const client = new UserClient();
	return client.remove(email);
}

export const changePasswordAction = (changePassword: ChangePasswordRequest) => {
	const client = new UserClient();
	return client.changePassword(changePassword);
}