import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { loginAction } from './action';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { tryCatchJsonByAction } from '../../utils/fetchUtils';
import { setFirstName, setToken } from '../../utils/tokenUtils';
import { RouteEnum, routes } from '../../settings/routes';
import { Input } from '../../components/Input/Input';
import { Button } from '../../components/Button/Button';
import { setRole } from '../../utils/rolesUtils';
import { LoginRequest, LoginResponse } from '../../swagger/putujmoAdminService';

export const Login = () => {
	const [loading, setLoading] = useState(false);

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const history = useHistory();

	const submitCallback = useCallback(
		async (e: any) => {
			e.preventDefault();
			setLoading(true);
			const loginDTO = new LoginRequest({email, password});
			const bindedAction = loginAction.bind(null, loginDTO)
			const loginResponse = await tryCatchJsonByAction(bindedAction);
			if (loginResponse.success) {
				const responseValue: LoginResponse = loginResponse.response; 
				if (responseValue.token) {
					setToken(responseValue.token);
					setRole(responseValue.user!.role);
					setFirstName(responseValue.user!.firstName || '');
					history.push(routes[RouteEnum.Home]);
				}	
			}
			setLoading(false);
		},
		[email, password, history]
	)

	return (
		<>
			<div className='login-container'>
				<header>Dobrodošli!</header>
				<form onSubmit={submitCallback} className='form'>
					<Input
						label='Unesite vaš email'
						value={email}
						onChange={(value) => setEmail(value)}
						disabled={loading}
					/>
					<Input
						type='password'
						label='Unesite vašu lozinku'
						value={password}
						onChange={(value) => setPassword(value)}
						disabled={loading}
					/>
					<Button
						type='submit'
						value='Prijava'
						disabled={loading || !email || !password}
						block
					/>
				</form>
			</div>
			{loading && <LoadingSpinner />}
		</>
	)
}