import { useEffect, useMemo, useState } from 'react';
import { LoadingSpinner } from './components/LoadingSpinner/LoadingSpinner';
import appSettings from './settings/appSettings';
import { BrowserRouter } from 'react-router-dom'
import { AccessRouter } from './containers/Routers/AccessRouter';

function App() {

	const [loading, setLoading] = useState(true);

	useEffect(
		() => {
			const fetchData = async ()  => {
				let filename;
				if (process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
					filename = 'appSettings_development.json'
				} else {
					filename = 'appSettings.json'
				}
				const settingsString = await (await fetch(`${process.env.PUBLIC_URL}/appSettings/${filename}`)).text();
				appSettings.setSettings(JSON.parse(settingsString));

				setLoading(false);
			}
			fetchData();
		},
		[]
	);

	const content = useMemo(
		() => {
			if (loading) {
				return (
					<LoadingSpinner />
				)
			} else {
				return (
					<BrowserRouter>
						<AccessRouter />	
					</BrowserRouter>
				)
			}
		},
		[loading]
	);

	return content;
}

export default App;
