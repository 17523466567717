import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { ListComponentProps } from '../../Routers/CrudRouter';
import { useTableColumnsMemo } from './tableColumns';
import { tryCatchJsonByAction } from '../../../utils/fetchUtils';
import { TableButtons } from '../../../components/Table/TableButtons';
import { Table } from '../../../components/Table/Table';
import { PutujmoSite, RegistrationState, RegistrationUserResponse } from '../../../swagger/putujmoAdminService';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { MultiSelect } from '../../../components/MultiSelect/MultiSelect';
import { siteOptions } from '../../../utils/languageUtils';
import { deleteRegisteredUserAction, getAllRegisteredUsersAction } from '../action';
import { registerStatusOptions } from '../utils';

export const RegistrationConfirmTable = ({dataChangedTopic, publishDataChanged}: ListComponentProps) => {
	const [putujmoSite, setPutujmoSite] = useState(PutujmoSite.Serbia);
	const [state, setState] = useState(RegistrationState.New);
	const [selectedId, setSelectedId] = useState<string | number | undefined>(undefined);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<RegistrationUserResponse[]>([]);

	const columns = useTableColumnsMemo();

	const fetchDataCallback = useCallback(
		async () => {
			setLoading(true);
			const bindedAction = getAllRegisteredUsersAction.bind(null, putujmoSite, state)
			var getResponse = await tryCatchJsonByAction(bindedAction);
			if (getResponse.success) {
				const items = getResponse.response || [];
				setData(items);
			}
			setLoading(false);
		},
		[putujmoSite, state]
	)

	useEffect(
		() => {
			fetchDataCallback();
		},
		[fetchDataCallback]
	)

	useEffect(
		() => {
			const subscription = dataChangedTopic?.subscribe(fetchDataCallback);
			return () => {
				subscription?.unsubscribe();
			}
		},
		[fetchDataCallback, dataChangedTopic]
	)

	const deleteCallback = useCallback(
		async (id: number) => {
			const bindedAction = deleteRegisteredUserAction.bind(null, id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				toast.success('Element je uspešno obrisan');
				setSelectedId(undefined);
				publishDataChanged();
			}
		},
		[publishDataChanged]
	)

	return (
		<div>
			<div className='flex-start-container'>
				<div className='m5'>
					<MultiSelect
						value={[putujmoSite]}
						items={siteOptions}
						onChange={(value) => {
							setPutujmoSite(value.length > 0 ? value[0] : PutujmoSite.Serbia)
						}}
						getItemId={(item: any) => item.id}
						getItemText={(item: any) => item.text}
					/>
				</div>
				<div className='m5'>
					<MultiSelect
						value={[state]}
						items={registerStatusOptions}
						onChange={(value) => {
							setState(value.length > 0 ? value[0] : RegistrationState.New)
						}}
						getItemId={(item: any) => item.id}
						getItemText={(item: any) => item.text}
					/>
				</div>
			</div>
			<TableButtons
				selectedId={selectedId}
				onDelete={deleteCallback}
				isViewable
				isChangeable={false}
				isCreateable={false}
			/>
			<Table
				columns={columns}
				data={data}
				onSelectedChange={setSelectedId}
			/>
			{loading && <LoadingSpinner />}
		</div>
	)
}
