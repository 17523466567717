import 'react-tabulator/lib/styles.css';
import 'react-tabulator/lib/css/tabulator.min.css';
import { ColumnDefinition, ReactTabulator } from 'react-tabulator';
import { useCallback, useRef } from 'react';
import { Tabulator } from 'react-tabulator/lib/types/TabulatorTypes';

type Props = {
	columns: ColumnDefinition[]
	data: object[]
	onSelectedChange?: (selectedId: number | string | undefined) => void
	onSortChange?: (data: any[]) => void
	isReorder?: boolean,
	idPropName?: string
}

export const Table = ({columns, data, onSelectedChange, onSortChange, idPropName = 'id', isReorder }: Props) => {
	let tabulatorRef = useRef<Tabulator>();

	const selectChange = useCallback(
		(data: any[]) => {
			if (!onSelectedChange) {
				return;
			}
			if (data && data.length === 1 && onSelectedChange) {
				onSelectedChange(data[0][idPropName]);
			} else {
				onSelectedChange(undefined)
			}
		},
		[onSelectedChange, idPropName]
	)

	const sortChange = useCallback(
		(sorters: any, rows: any[]) => {
			onSortChange && onSortChange(rows.map(x => x.getData()))
		},
		[onSortChange]
	)

	const orderChanged = useCallback(
		() => {
			if (tabulatorRef.current) {
				onSortChange && onSortChange(tabulatorRef.current.getData())
			}
		},
		[onSortChange]
	)
	
	return (
		<div className='table-container'>
			<ReactTabulator
				onRef={(ref) => (tabulatorRef.current = ref.current)}
				columns={columns}
				data={data}
				events={{
					rowSelectionChanged: selectChange,
					dataSorted: sortChange,
					rowMoved: orderChanged
				}}
				options={{
					selectable: 1,
					movableRows: isReorder,
					layout: 'fitDataStretch',
					movableColumns: true,
				}}
			/>
		</div>
	  )
}