type Props = {
	value: string
	onClick?: () => void
	type?: 'button' | 'submit' | 'reset'
	disabled?: boolean
	block?: boolean
	outline?: boolean
}

export const Button = ({value, type = 'button', onClick, disabled, block, outline}: Props) => {
	return (
		<button
			className={`button ${block ? 'block-button' : ''} ${outline ? 'outline' : ''}`}
			children={value}
			type={type}
			disabled={disabled}
			onClick={onClick}
		/>
	)
}