import React, { useMemo } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Subject } from 'rxjs'
import { CrudEnum } from '../../settings/routes';

export type ParamType = {
	id: string
}

export type ListComponentProps = {
	dataChangedTopic: Subject<unknown>
	publishDataChanged(): void
	additionalData?: any
}

export type CreateComponentProps = {
	publishDataChanged(): void
	additionalData?: any
}

export type ReadComponentProps = CreateComponentProps;

export type UpdateComponentProps = CreateComponentProps;

export type AdditionalRoutesProps = {
	publishDataChanged(): void
	additionalData?: any
}

export const useSubscriptionMemo = () => {
	return useMemo(
		() => {
			const dataChangedTopic = new Subject();
			const publishDataChanged = () => dataChangedTopic.next();

			return {
				dataChangedTopic,
				publishDataChanged
			}
		},
		[]
	)
}

type ListWrapperProps = ListComponentProps & {
	ListComponent: any
	isModal?: boolean
}

const ListComponentWrapper = ({ ListComponent, dataChangedTopic, publishDataChanged, isModal, additionalData }: ListWrapperProps) => {
	const routematch = useRouteMatch();
	return (
		<div style={{ display: routematch.isExact || isModal ? '' : 'none' }}>
			<ListComponent
				dataChangedTopic={dataChangedTopic}
				publishDataChanged={publishDataChanged}
				additionalData={additionalData}
			/>
		</div>
	)
}

type Props = {
	ListComponent: React.FunctionComponent<ListComponentProps>
	CreateComponent?: React.FunctionComponent<CreateComponentProps>
	ReadComponent?: React.FunctionComponent<ReadComponentProps>
	UpdateComponent?: React.FunctionComponent<UpdateComponentProps>
	AdditionalRoutes?: React.FunctionComponent<AdditionalRoutesProps>
	paramName: string | string[]
	isModal?: boolean
	additionalData?: any
}

export const CrudRouter = ({ ListComponent, CreateComponent, ReadComponent, UpdateComponent, AdditionalRoutes, paramName, isModal=true, additionalData }: Props) => {
	let { path } = useRouteMatch();
	const { dataChangedTopic, publishDataChanged } = useSubscriptionMemo();

	const params = useMemo(
		() => {
			let result = '';
			if (typeof paramName === 'string') {
				result = `:${paramName}`;
			} else {
				result = `:${paramName[0]}`
				for (let i = 1; i < paramName.length; i++) {
					result += `/:${paramName[i]}`;
				}
			}
			return result;
		},
		[paramName]
	)

	return (
		<>
			<Switch>
				{CreateComponent &&
					<Route
						path={`${path}/${CrudEnum.Create}`}
						render={() => <CreateComponent publishDataChanged={publishDataChanged} additionalData={additionalData} />}
					/>
				}
				{ReadComponent &&
					<Route
						path={`${path}/${CrudEnum.Read}/${params}`}
						render={() => <ReadComponent publishDataChanged={publishDataChanged}  additionalData={additionalData} />}
					/>
				}
				{UpdateComponent &&
					<Route
						path={`${path}/${CrudEnum.Update}/${params}`}
						render={() => <UpdateComponent publishDataChanged={publishDataChanged} additionalData={additionalData} />}
					/>
				}
				{AdditionalRoutes && <AdditionalRoutes publishDataChanged={publishDataChanged}  additionalData={additionalData} />}
			</Switch>
			<Route
				render={() => (
					<ListComponentWrapper
						ListComponent={ListComponent}
						dataChangedTopic={dataChangedTopic}
						publishDataChanged={publishDataChanged}
						isModal={isModal}
						additionalData={additionalData}
					/>
				)}
			/>
		</>
	)
}
