import { useMemo } from 'react';
import { RegistrationUserResponse } from '../../../swagger/putujmoAdminService';
import { propertyOf } from '../../../utils/propertyOf';
import { formatDate } from '../../../utils/dateUtils';
import { ColumnDefinition } from 'react-tabulator';

export const useTableColumnsMemo = () => {

	const columns: ColumnDefinition[] = useMemo(
		() => {
			return [
				{
					title:'Email', 
					field: propertyOf<RegistrationUserResponse>('email')
				},
				{
					title:'Ime', 
					field: propertyOf<RegistrationUserResponse>('firstName')
				},
				{
					title:'Prezime', 
					field: propertyOf<RegistrationUserResponse>('lastName')
				},
				{
					title:'Naziv objekta',
					field: 'propertyName',
					formatter: (cell: any) => {
						const item: RegistrationUserResponse = cell.getData();
						return item.property?.propertyName || ''
					}
				},
				{
					title:'Telefon', 
					field: propertyOf<RegistrationUserResponse>('phone')
				},
				{
					title:'Paket', 
					field: propertyOf<RegistrationUserResponse>('packageType')
				},
				{
					title:'Mesto/grad',
					field: 'propertyCity',
					formatter: (cell: any) => {
						const item: RegistrationUserResponse = cell.getData();
						return item.property?.propertyCity || ''
					}
				},
				{
					title: 'Poslednja promena',
					field: propertyOf<RegistrationUserResponse>('lastUpdated'),
					formatter: (cell: any) => formatDate(cell.getValue())
				}
			]
		},
		[]
	)

	return columns;
}