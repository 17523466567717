import { PersonalDocumentType, PropertyUnitNumber, RegisteredUserType, RegistrationState, RentPropertyUnitType, ServiceType } from '../../swagger/putujmoAdminService';

export const registerStatusOptions = [
	{ id: RegistrationState.RegistrationStarted, text: 'Započeta registraciju' },
	{ id: RegistrationState.EmailConfirmed, text: 'Potvrđen email' },
	{ id: RegistrationState.WaitingForPayment, text: 'Na plaćanju' },
	{ id: RegistrationState.New, text: 'New' },
	{ id: RegistrationState.Live, text: 'Live' },
	{ id: RegistrationState.Archived, text: 'Arhiviran' },
]

export const oneToTenNumberOptions = [
	{id: PropertyUnitNumber.One, text: '1' },
	{id: PropertyUnitNumber.Two, text: '2' },
	{id: PropertyUnitNumber.Three, text: '3' },
	{id: PropertyUnitNumber.Four, text: '4' },
	{id: PropertyUnitNumber.Five, text: '5' },
	{id: PropertyUnitNumber.Six, text: '6' },
	{id: PropertyUnitNumber.Seven, text: '7' },
	{id: PropertyUnitNumber.Eight, text: '8' },
	{id: PropertyUnitNumber.Nine, text: '9' },
	{id: PropertyUnitNumber.Ten, text: '10' },
	{id: PropertyUnitNumber.ElevenPlus, text: '11+' },
]

export const getOneToTenLabel = (unitNumber: PropertyUnitNumber | undefined) => {
	return oneToTenNumberOptions.find(x => x.id === unitNumber)?.text;
}

export const getRegisterStatusLabel = (state: RegistrationState | undefined) => {
	return registerStatusOptions.find(x => x.id === state)?.text;
}

export const getUserTypeLabel = (type: RegisteredUserType | undefined) => {
	switch(type) {
		case RegisteredUserType.Individiual:
			return 'Fizičko lice'
		case RegisteredUserType.Entrepreneur:
			return 'Preduzetnik'
		case RegisteredUserType.LEgalEntity:
			return 'Pravno lice'
		default:
			return ''
	}
}

export const getRentPropertyUnitTypeLabel = (type: RentPropertyUnitType | undefined) => {
	switch(type) {
		case RentPropertyUnitType.PerUnit:
			return 'Po jedinici'
		case RentPropertyUnitType.PerOccupancy:
			return 'Po osobi'
		default:
			return ''
	}
}

export const getServiceTypeLabel = (type: ServiceType | undefined) => {
	switch(type) {
		case ServiceType.BedOnly:
			return 'SAMO NOĆENJE'
		case ServiceType.BB:
			return 'NOĆENJE SA DORUČKOM'
		case ServiceType.HB:
			return 'POLUPANSION'
		case ServiceType.FB:
			return 'PUN PANSION'
		case ServiceType.AllInc:
			return 'ALL INCLUSIVE'
		default:
			return ''
	}
}

export const getPersonalDocumentTypeLabel = (type: PersonalDocumentType | undefined) => {
	switch(type) {
		case PersonalDocumentType.IdCard:
			return 'Lična karta'
		case PersonalDocumentType.Passport:
			return 'Pasoš'
		default:
			return ''
	}
}