export const formatDate = (value: Date | undefined) => {
	if (!value) {
		return '';
	}

	let date, month, year;

	date = value.getDate();
	month = value.getMonth() + 1;
	year = value.getFullYear();

	date = date.toString().padStart(2, '0');
	month = month.toString().padStart(2, '0');
	
	return `${date}.${month}.${year}`
}

export const convertTicksToTime = (value: number | undefined) => {

	if (value === undefined) {
		return '';
	}

	const timeInMilliSeconds = value / 10000;
	const timeInSeconds = timeInMilliSeconds / 1000;
	const timeInMinutes = Math.floor(timeInSeconds / 60);

	const minutes = timeInMinutes % 60;
	let hours = Math.floor(timeInMinutes / 60);

	return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
}
