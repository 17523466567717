import { useCallback, useMemo, useState } from 'react';
import { Button } from '../../components/Button/Button';
import { LabelText } from '../../components/Input/LabelText';
import { RegisteredUserType, RegistrationState, RegistrationUserResponse, RentPropertyUnitType } from '../../swagger/putujmoAdminService';
import { convertTicksToTime, formatDate } from '../../utils/dateUtils';
import { downloadImage } from '../../utils/imageUtils';
import { getOneToTenLabel, getPersonalDocumentTypeLabel, getRegisterStatusLabel, getRentPropertyUnitTypeLabel, getServiceTypeLabel, getUserTypeLabel, registerStatusOptions } from './utils';
import { MultiSelect } from '../../components/MultiSelect/MultiSelect';

type Props = {
	values?: RegistrationUserResponse
	onSubmit: (newState: RegistrationState) => void
	onCancel: () => void
}

export const RegistrationConfirmForm = ({values, onSubmit, onCancel}: Props) => {

	const [state, setState] = useState(values?.state);

	const submitCallback = useCallback(
		() => {
			onSubmit(state!);
		},
		[state, onSubmit]
	)

	const trialExpireDateMemo = useMemo(
		() => {
			if (!values || !values.registrationFinished) {
				return;
			}
			const dateCopy = new Date(values.registrationFinished);
			const result = new Date(dateCopy);
			result.setDate(dateCopy.getDate() + 180);
			return result
		},
		[values]
	)

	return (
		<form onSubmit={submitCallback}>
			<MultiSelect
				label='Promeni status'
				value={[state]}
				items={registerStatusOptions}
				onChange={(value) => {
					setState(value.length > 0 ? value[0] : undefined)
				}}
				getItemId={(item: any) => item.id}
				getItemText={(item: any) => item.text}
			/>
			<div className='flex-end-container'>
				<Button
					type='submit'
					value='Prihvati'
					disabled={!state}
				/>
				<Button
					type='button'
					value='Odustani'
					onClick={onCancel}
					outline
				/>
			</div>
			<div className='flex-between-container m5'>
				<div>
					<h4 className='registration-header'>Podaci o registraciji</h4>
					<LabelText
						label='Izabrani paket'
						value={values?.packageType}
					/>
					<LabelText
						label='180 dana probno'
						value={values?.isTrial ? 'Da': 'Ne'}
					/>
					{values?.isTrial && (
						<LabelText
							label='Probni period'
							value={`${formatDate(values?.registrationFinished)} - ${formatDate(trialExpireDateMemo)}`}
						/>
					)}
					<LabelText
						label='Zainteresovan za sajt'
						value={values?.registerWithWebsite ? 'Da': 'Ne'}
					/>
					<LabelText
						label='Zainteresovan za online rezervaciju na svom sajtu'
						value={values?.registerWithOnlineBookingForm ? 'Da': 'Ne'}
					/>
					<LabelText
						label='Status'
						value={getRegisterStatusLabel(values?.state)}
					/>
					<LabelText
						label='Poslednje ažuriranje'
						value={formatDate(values?.lastUpdated)}
					/>
				</div>
				<div>
					<h4 className='registration-header'>Podaci o vlasniku</h4>
					<LabelText
						label='Ime i prezime'
						value={`${values?.firstName} ${values?.lastName}`}
					/>
					<LabelText
						label='Email'
						value={values?.email}
					/>
					<LabelText
						label='Telefon'
						value={values?.phone}
					/>
					<LabelText
						label='Tip korisnika'
						value={getUserTypeLabel(values?.userType)}
					/>
					{values?.userType === RegisteredUserType.Individiual && (
						<>
							<LabelText
								label='Tip dokumenta'
								value={getPersonalDocumentTypeLabel(values?.personalDocumentType)}
							/>
							<LabelText
								label='Broj dokumenta'
								value={values?.personalDocumentNumber}
							/>
							<h4 className='registration-header'>Slike dokumenata</h4>
							{values?.personalDocumentImages?.map((x, index) => (
								<div className='img-container' key={index}>
									<img src={x} alt='img' />
									<Button value='Snimi sliku' onClick={() => downloadImage(x)} />
								</div>
							))}
						</>
					)}
					{values?.userType !== RegisteredUserType.Individiual && (
						<>
							<LabelText
								label='Ime firme/predzetnika'
								value={values?.contractorName}
							/>
							<LabelText
								label='MB'
								value={values?.mb}
							/>
							<LabelText
								label='PIB'
								value={values?.pib}
							/>
							<LabelText
								label='U sistemu PDV-a'
								value={values?.isVat ? 'Da': 'Ne'}
							/>
						</>
					)}
					<LabelText
						label='Ima postavljen oglas'
						value={values?.hasBookingAd ? 'Da': 'Ne'}
					/>
				</div>

			</div>
			<div className='flex-between-container m5'>
				<div>
					<h4 className='registration-header'>Podaci o objektu</h4>
					<LabelText
						label='Naziv objekta'
						value={values?.property?.propertyName}
					/>
					<LabelText
						label='Adresa'
						value={values?.property?.propertyAddress}
					/>
					<LabelText
						label='Mesto/Grad'
						value={values?.property?.propertyCity}
					/>
					<LabelText
						label='Poštanski broj'
						value={values?.property?.propertyZipcode}
					/>
					<LabelText
						label='Objekat kategorizovan'
						value={values?.property?.isPropertyCategorized ? 'Da': 'Ne'}
					/>
					{values?.property?.isPropertyCategorized && (
						<>
							<LabelText
								label='Broj rešenja o kategorizaciji'
								value={values?.property.propertyCategorizationNumber}
							/>
							<LabelText
								label='Ko je izdao rešenje?'
								value={values?.property.propertyCategorizationIssuer}
							/>
							<LabelText
								label='Broj zvezdica'
								value={values?.property.propertyCategorizationRank}
							/>
						</>
					)}
					<LabelText
						label='Broj smeštajnih jedinica'
						value={getOneToTenLabel(values?.property?.propertyUnitNumber)}
					/>
					<LabelText
						label='Email za rezervaciju'
						value={values?.property?.reservationEmail}
					/>
					<LabelText
						label='Telefon'
						value={values?.property?.reservationPhone}
					/>
					<LabelText
						label='Check in'
						value={convertTicksToTime(values?.property?.checkIn)}
					/>
					<LabelText
						label='Check out'
						value={convertTicksToTime(values?.property?.checkOut)}
					/>
					<LabelText
						label='Web sajt'
						value={values?.property?.website}
					/>
					<LabelText
						label='Facebook'
						value={values?.property?.facebookPage}
					/>
					<LabelText
						label='Instagram'
						value={values?.property?.instagramPage}
					/>
					<LabelText
						label='Geografska širina'
						value={values?.property?.latitude}
					/>
					<LabelText
						label='Geografska dužina'
						value={values?.property?.longitude}
					/>
					<LabelText
						label='Opis'
						value={values?.property?.description}
					/>
				</div>
				<div>
					<h4 className='registration-header'>Sadržaji</h4>
					{values?.property?.phobsChecklist?.split(',').map((x, index) => (
						<div key={index}>{x}</div>
					))}
					<h4 className='registration-header'>Slike objekta</h4>
					{values?.property?.images?.map((x, index) => (
						<div className='img-container' key={index}>
							<img src={x} alt='img' />
							<Button value='Snimi sliku' onClick={() => downloadImage(x)} />
						</div>
					))}
				</div>
			</div>
			<h3 className='registration-header'>Podaci o tipovima smeštaja</h3>
			{values?.property?.units?.map((unit, index) => (
				<div className='flex-between-container m5' key={index}>
					<div>
						<h3 className='registration-header'>{unit.name}</h3>
						<LabelText
							label='Tip smeštajne jedinice'
							value={unit?.name}
						/>
						<LabelText
							label='Površina (m2)'
							value={unit?.surfaceArea}
						/>
						<LabelText
							label='Broj smeštajnih jedinica'
							value={unit?.numberOfUnits}
						/>
						<LabelText
							label='Standardni kapacitet'
							value={unit?.standardCapacity}
						/>
						<LabelText
							label='Minimalni kapacitet'
							value={unit?.minCapacity}
						/>
						<LabelText
							label='Maksimalni kapacitet'
							value={unit?.maxCapacity}
						/>
						<LabelText
							label='Minimalni broj odraslih'
							value={unit.minNumOfAdults}
						/>
						<LabelText
							label='Maksimalni broj odraslih'
							value={unit.maxNumOfAdults}
						/>
						<LabelText
							label='Minimalni broj dece'
							value={unit.minNumOfChildren}
						/>
						<LabelText
							label='Maksimalni broj dece'
							value={unit.maxNumOfChildren}
						/>
						<LabelText
							label='Maksimalna starost deteta'
							value={unit.maxAgefChildren}
						/>
						<LabelText
							label='Opis'
							value={unit.description}
						/>
						<LabelText
							label='Izdavanje smeštaja'
							value={getRentPropertyUnitTypeLabel(unit.rentPropertyUnitType)}
						/>
						<LabelText
							label='Minimalan broj noćenja'
							value={unit.minNumberOfNights}
						/>
						{unit.minNumberOfNights > 1 && (
							<>
								<LabelText
									label='Rezervaciju na 1 noć uz uvećanje'
									value={unit.allowOneNight ? 'Da': 'Ne'}
								/>
								{unit.allowOneNight && (
									<LabelText
										label='Procenat uvećanja za 1 noć'
										value={unit.priceIncreaseForOneNight}
									/>
								)}
							</>
						)}
						<LabelText
							label='Boravišna taksa uključena u cenu'
							value={unit.isLocalTaxesIncluded ? 'Da': 'Ne'}
						/>
						{!unit.isLocalTaxesIncluded && (
							<>
								<LabelText
									label='Odrasle osobe - cena boravišne takse'
									value={unit.adultAmountPayLocalTaxes}
								/>
								<LabelText
									label={`Deca od 0 - ${unit.maxYearOfChildDontPayLocalTaxes}`}
									value='Ne plaćaju boravišnu taksu'
								/>
								<LabelText
									label={`Deca od ${unit.minYearOfChildPayLocalTaxes} - ${unit.maxYearOfChildPayLocalTaxes} - cena boravišne takse`}
									value={unit.childAmountPayLocalTaxes}
								/>
							</>
						)}
						<LabelText
							label='Popust na broj noćenja'
							value={unit.allowDiscountForLongStay ? 'Da': 'Ne'}
						/>
						{unit.allowDiscountForLongStay && unit.longStayDiscounts?.map((longStayDiscount, index) => (
							<LabelText
								key={index}
								label={`${longStayDiscount.fromDays} - ${longStayDiscount.toDays} noćenja`}
								value={`Popust - ${longStayDiscount.discountPercentage} %`}
							/>
						))}
						<h4 className='registration-header'>Cenovnici za {unit.name}</h4>
						{unit.pricelists?.map((pricelist, index) => (
							unit.rentPropertyUnitType === RentPropertyUnitType.PerUnit ? (
							<>
								<LabelText
									key={index}
									label={getServiceTypeLabel(pricelist.serviceType)}
									value={pricelist.standardPrice}
								/>
								{pricelist.childDiscounts && pricelist.childDiscounts.length > 0 && (
									<>
										<h4 className='registration-header'>Cene za decu {getServiceTypeLabel(pricelist.serviceType)}</h4>
										{pricelist.childDiscounts.map((childDiscount, index) => (
											<>
												<LabelText
													key={index}
													label={`${childDiscount.fromAge} - ${childDiscount.toAge} godina`}
													value={childDiscount.price}
												/>
											</>
										))}
									</>
								)}
							</>		
							) : (
								<>
									<h4 className='registration-header'>{getServiceTypeLabel(pricelist.serviceType)}</h4>
									{pricelist.pricelistOccupancies?.map((pricelistOccupancy, index) => (
										<LabelText
											key={index}
											label={`${pricelistOccupancy.numberOfAdults} osoba`}
											value={pricelistOccupancy.price}
										/>
									))}
									{pricelist.childDiscounts && pricelist.childDiscounts.length > 0 && (
									<>
										<h4 className='registration-header'>Cene za decu {getServiceTypeLabel(pricelist.serviceType)}</h4>
										{pricelist.childDiscounts.map((childDiscount, index) => (
											<>
												<LabelText
													key={index}
													label={`${childDiscount.fromAge} - ${childDiscount.toAge} godina`}
													value={childDiscount.price}
												/>
											</>
										))}
									</>
								)}
								</>
							)
						))}
					</div>
					<div>
					<div>
						<h4 className='registration-header'>Sadržaji</h4>
						{unit.phobsChecklist?.split(',').map((x, index) => (
							<div key={index}>{x}</div>
						))}
						<h4 className='registration-header'>Slike objekta</h4>
						{unit.images?.map((x, index) => (
							<div className='img-container' key={index}>
								<img src={x} alt='img' />
								<Button value='Snimi sliku' onClick={() => downloadImage(x)} />
							</div>
						))}
					</div>
					</div>
				</div>
			))}
		</form>
	)
}