import { PropertyClient, PutujmoSite, SimplePropertyClient, UpdatePropertyOrdersRequest } from '../../swagger/putujmoAdminService';

export const getAllSimplePropertiesAction = (putujmoSite: PutujmoSite | undefined, language: string) => {
	const client = new SimplePropertyClient();
	return client.getAll(putujmoSite, language);
}

export const deletePropertyAction = (putujmoSite: PutujmoSite | undefined, propertyId: string) => {
	const client = new PropertyClient();
	return client.delete(putujmoSite, propertyId);
}

export const updatePropertyOrdersAction = (model: UpdatePropertyOrdersRequest) => {
	const client = new PropertyClient();
	return client.updateOrders(model);
}