import { propertyOf } from '../../utils/propertyOf'
import { CrudRouter, ParamType } from '../Routers/CrudRouter'
import { CreateUser } from './Crud/CreateUser'
import { ReadUser } from './Crud/ReadUser'
import { UsersTable } from './Table/UsersTable'

export const UserRouter = () => {
	return (
		<CrudRouter
			ListComponent={UsersTable}
			ReadComponent={ReadUser}
			CreateComponent={CreateUser}
			paramName={propertyOf<ParamType>('id')}
		/>
	)
}