import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { CreateComponentProps } from '../../Routers/CrudRouter';
import { useGoBackFromCreate } from '../../../utils/crudHooks';
import { InsertManagementUserRequest } from '../../../swagger/putujmoAdminService';
import { createUserAction } from '../../../features/users/action';
import { tryCatchJsonByAction } from '../../../utils/fetchUtils';
import { Dialog } from '../../../components/Dialog/Dialog';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { CrudEnum } from '../../../settings/routes';
import { UserForm } from '../UserForm';

export const CreateUser = ({ publishDataChanged }: CreateComponentProps) => {
	const [loading, setLoading] = useState(false);
	const goBackFromCreate = useGoBackFromCreate();

	const handleSave = useCallback(
		async (newValues: InsertManagementUserRequest) => {
			setLoading(true);
			const bindedAction = createUserAction.bind(null, newValues);
			const response = await tryCatchJsonByAction(bindedAction);
			setLoading(false);

			if (response.success) {
				toast.success('Novi element je uspešno kreiran.');
				publishDataChanged();
				goBackFromCreate();
			} 
		},
		[goBackFromCreate, publishDataChanged]
	);

	return (
		<Dialog
			title='Kreiranje novog elementa'
			onClose={goBackFromCreate}
			open
		>
			<UserForm
				onSubmit={handleSave}
				onCancel={goBackFromCreate}
				crudEnum={CrudEnum.Create}
			/>
			{loading && <LoadingSpinner />}
		</Dialog>
	)
}
