import { useCallback, useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw,  RawDraftContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export const textEditorId = 'textEditorId';

const initialEditorContent= (value: string | undefined) => {
	if (value) {
		const rawState: RawDraftContentState = JSON.parse(value)

		return rawState;
	}
	return convertToRaw(EditorState.createEmpty().getCurrentContent())
}

type Props = {
	value: string
	onChange: (value: string) => void
	onBlur?: () => void
	disabled?: boolean
}


export const TextEditor = ({ value, onChange, onBlur, disabled }: Props) => {

	const [fixedToolbar, setFixedToolbar] = useState(false);

	const onEditorStateChange = useCallback(
		(newEditorState: EditorState) => {
			const raw = convertToRaw(newEditorState.getCurrentContent());
			onChange && onChange(JSON.stringify(raw));
		},
		[onChange]
	);

	const handleScrollCallback = useCallback(
		(event: Event) => {
			const element = window.document.querySelector('.dialog-container')
			if (element) {
				if (element.scrollTop >= 400) {
					setFixedToolbar(true)
				} else {
					setFixedToolbar(false);
				}
			}
		},
		[]
	)

	useEffect(
		() => {
			const element = window.document.querySelector('.dialog-container')
			if (element) {
				element.addEventListener('scroll', handleScrollCallback,);
				return () => { element.removeEventListener('scroll', handleScrollCallback); }
			}
			
		},
		[handleScrollCallback]
	)

	return (
		<div id={textEditorId}>
			<Editor
				initialContentState={initialEditorContent(value)}
				onEditorStateChange={onEditorStateChange}
				toolbarClassName={fixedToolbar ? 'text-editor-fixed-toolbar' : ''}
				editorClassName='text-editor'
				onBlur={onBlur}
				readOnly={disabled}
				toolbarHidden={disabled}
				handlePastedText={() => false}
			/>
		</div>
	)
}
