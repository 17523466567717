import { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { RouteEnum, routes } from '../../settings/routes';
import { useIsLogedInUserAdminMemo, useIsLogedInUserSuperAdminMemo } from '../../utils/rolesUtils';
import { useGetFirstName } from '../../utils/tokenUtils';

export const Sidebar = () => {

	const history = useHistory();

	const isUserAdminMemo = useIsLogedInUserAdminMemo();
	const isuserSuperAdminMemo = useIsLogedInUserSuperAdminMemo();

	const firstNameMemo = useGetFirstName();

	const logoutCallback = useCallback(
		() => {
			localStorage.clear();
			history.push(routes[RouteEnum.Login]);
		},
		[history]
	)

	return (
		<div id='pgside'>
			<div id='pguser'>
				<img src='/potato.png' alt='user-icon' />
				<i className='txt'>{firstNameMemo}</i>
			</div>
			<Link to={routes[RouteEnum.Blogs]} className={history.location.pathname.includes(routes[RouteEnum.Blogs]) ? 'current': ''}>
				<i className='ico'>&#9728;</i>
				<i className='txt'>Blog</i>
			</Link>
			<Link to={routes[RouteEnum.HistoricalHeritage]} className={history.location.pathname.includes(routes[RouteEnum.HistoricalHeritage]) ? 'current': ''}>
				<i className='ico'>&#9751;</i>
				<i className='txt'>Istorijsko nasledje</i>
			</Link>
			<Link to={routes[RouteEnum.RealEstate]} className={history.location.pathname.includes(routes[RouteEnum.RealEstate]) ? 'current': ''}>
				<i className='ico'>&#9963;</i>
				<i className='txt'>Nekretnine</i>
			</Link>
			<Link to={routes[RouteEnum.Authenticity]} className={history.location.pathname.includes(routes[RouteEnum.Authenticity]) ? 'current': ''}>
				<i className='ico'>&#9753;</i>
				<i className='txt'>Autentičnost zemlje</i>
			</Link>
			{(isUserAdminMemo || isuserSuperAdminMemo) && 
				<Link to={routes[RouteEnum.RegistrationConfirm]} className={history.location.pathname.includes(routes[RouteEnum.RegistrationConfirm]) ? 'current': ''}>
					<i className='ico'>&#9733;</i>
					<i className='txt'>Objekti za potvrdu</i>
				</Link>
			}
			{isuserSuperAdminMemo && 
				<Link to={routes[RouteEnum.User]} className={history.location.pathname.includes(routes[RouteEnum.User]) ? 'current': ''}>
					<i className='ico'>&#9737;</i>
					<i className='txt'>Korisnici</i>
				</Link>
			}
			{isuserSuperAdminMemo && 
				<Link to={routes[RouteEnum.Categories]} className={history.location.pathname.includes(routes[RouteEnum.Categories]) ? 'current': ''}>
					<i className='ico'>&#9732;</i>
					<i className='txt'>Kategorije</i>
				</Link>
			}
			{isuserSuperAdminMemo && 
				<Link to={routes[RouteEnum.PhobsProperties]} className={history.location.pathname.includes(routes[RouteEnum.PhobsProperties]) ? 'current': ''}>
					<i className='ico'>&#10224;</i>
					<i className='txt'>Phobs objekti</i>
				</Link>
			}
			<Link to='#' onClick={logoutCallback}>
				<i className='ico'>&#10149;</i>
				<i className='txt'>Odjava</i>
			</Link>
	  </div>
	)
}