import { useMemo } from 'react';
import { CategoryResponse } from '../../../swagger/putujmoAdminService';
import { propertyOf } from '../../../utils/propertyOf';
import { ColumnDefinition } from 'react-tabulator';

export const useTableColumnsMemo = () => {

	const columns: ColumnDefinition[] = useMemo(
		() => {
			return [
				{
					title:'Naslov', 
					field: propertyOf<CategoryResponse>('name')
				}
			]
		},
		[]
	)

	return columns;
}