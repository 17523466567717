import React from 'react';
import App from './App';
import 'react-toastify/dist/ReactToastify.css'
import './index.css'
import { ToastContainer } from 'react-toastify';
import { render } from'react-dom'

render(
  <React.StrictMode>
    <App />
	<ToastContainer />
  </React.StrictMode>,
  document.getElementById("root")
);