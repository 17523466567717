export interface AppSettingsData {
	apiUrl: string
}

export class AppSettings {
	private _apiUrl: string = '';


    public setSettings(values: AppSettingsData) {
		 this._apiUrl = values.apiUrl;
	}

	public getApiUrl(): string {
		return this._apiUrl;
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AppSettings();
