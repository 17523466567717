import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { RouteEnum, routes } from '../../settings/routes'
import { useIsLogedInUserAdminMemo, useIsLogedInUserSuperAdminMemo } from '../../utils/rolesUtils';
import { BlogRouter } from '../Article/Types/Blog/BlogRouter';
import { RegistrationConfirmRouter } from '../RegistrationConfirm/RegistrationConfirmRouter';
import { UserRouter } from '../UserManagement/UserRouter';
import { HistoricalHeritageRouter } from '../Article/Types/HistoricalHeritage/HistoricalHeritageRouter';
import { RealEstateRouter } from '../Article/Types/RealEstate/RealEstateRouter';
import { AuthenticityRouter } from '../Article/Types/Authenticity/AuthenticityRouter';
import { Categories } from '../Category/Categories';
import { PhobsProperties } from '../PhobsProperties/PhobsProperties';

export const ContentRouter = () => {

	const isUserAdminMemo = useIsLogedInUserAdminMemo();
	const isUserSuperAdminMemo = useIsLogedInUserSuperAdminMemo();

	const { pathname} = useLocation();
	if (pathname === routes[RouteEnum.Home]) {
		return <Redirect to={routes[RouteEnum.Blogs]} />
	}

	return (
		<main id="pgmain">
			<Switch>
				<Route path={routes[RouteEnum.Blogs]} component={BlogRouter} />
				<Route path={routes[RouteEnum.HistoricalHeritage]} component={HistoricalHeritageRouter} />
				<Route path={routes[RouteEnum.RealEstate]} component={RealEstateRouter} />
				<Route path={routes[RouteEnum.Authenticity]} component={AuthenticityRouter} />
				{(isUserAdminMemo || isUserSuperAdminMemo) && <Route path={routes[RouteEnum.RegistrationConfirm]} component={RegistrationConfirmRouter} /> }
				{isUserSuperAdminMemo &&<Route path={routes[RouteEnum.User]} component={UserRouter} /> }
				{isUserSuperAdminMemo &&<Route path={routes[RouteEnum.Categories]} component={Categories} /> }
				{isUserSuperAdminMemo &&<Route path={routes[RouteEnum.PhobsProperties]} component={PhobsProperties} /> }
			</Switch>
		</main>
	)
}