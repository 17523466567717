import { useCallback } from 'react'

type Props = {
	value: string | undefined
	onChange?: (value: string) => void
	label?: string
	type?: string
	placeholder?: string
	disabled?: boolean
	big?: boolean
}

export const Input = ({label, placeholder, value, onChange, type = 'text', disabled, big}: Props) => {
	
	const changeCallback = useCallback(
		(e: any) => {onChange && onChange(e.target.value)},
		[onChange]
	)
	
	return (
		<div>
			{label && (
				<div className='label-input'>
					<label >{label}</label>
				</div>
			)}
			<input
				className={`input ${big ? 'big-input' : ''}`}
				value={value}
				type={type}
				disabled={disabled}
				placeholder={placeholder}
				onChange={changeCallback}
			/>
		</div>

	)
}