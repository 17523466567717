import { useCallback, useState } from 'react';
import { ArticleResponse, UpdateArticleRequest } from '../../../swagger/putujmoAdminService';
import { CrudEnum } from '../../../settings/routes';
import { Input } from '../../../components/Input/Input';
import { Button } from '../../../components/Button/Button';
import { MultiSelect } from '../../../components/MultiSelect/MultiSelect';
import { PutujmoSite, ArticleType } from '../../../swagger/putujmoAdminService';
import { useGoBackFromCrudAndPush } from '../../../utils/crudHooks';
import { LanguageEnum, languageOptions, siteOptions } from '../../../utils/languageUtils';
import { TextEditor } from '../../../components/Editor/TextEditor';

type Props = {
	values?: ArticleResponse
	onSubmit?: (newValues: UpdateArticleRequest) => Promise<void>
	onCancel?: () => void
	crudEnum: CrudEnum
}

export const RealEstateForm = ({values, onSubmit, onCancel, crudEnum }: Props) => {
	const [putujmoSite, setPutujmoSite] = useState(values?.putujmoSite || PutujmoSite.Serbia);
	const [language, setLanguage] = useState(values?.language as LanguageEnum || LanguageEnum.Serbian);
	const [title, setTitle] = useState(values?.title || '');
	const [content, setContent] = useState(values?.content || '');
	const [investor, setInvestor] = useState(values?.investor || '');
	const [imageUrl, setImageUrl] = useState(values?.imageUrl || '');
	const [location, setLocation] = useState(values?.location || '');
	const [price, setPrice] = useState(values?.price || '');
	const [phone, setPhone] = useState(values?.phone || '');
	const [email, setEmail] = useState(values?.email || '');
	const [website, setWebsite] = useState(values?.website || '');

	const submitCallback = useCallback(
		async (e: any) => {
			e.preventDefault();
			const model = new UpdateArticleRequest();
			model.putujmoSite = putujmoSite;
			model.title = title;
			model.content = content;
			model.investor = investor;
			model.language = language;
			model.imageUrl = imageUrl;
			model.location = location
			model.price = price
			model.phone = phone
			model.email = email
			model.website = website
			model.articleType = ArticleType.RealEstate;
			onSubmit && await onSubmit(model);
		},
		[
			putujmoSite,
			title,
			content,
			investor,
			language,
			imageUrl,
			location,
			price,
			phone,
			email,
			website,
			onSubmit
		]
	)

	const isRead = crudEnum === CrudEnum.Read;

	const goBackFromCrudAndPush = useGoBackFromCrudAndPush(CrudEnum.Read, undefined, undefined);

	const goUpdateCallback = useCallback(
		() => {
			if (values && values.id) {
				goBackFromCrudAndPush(`${CrudEnum.Update}/${values?.id}`);
			}
		},
		[values, goBackFromCrudAndPush]
	)

	return (
		<form onSubmit={submitCallback}>
			<div className='flex-between-container'>
				<MultiSelect
					label='Sajt'
					value={[putujmoSite]}
					items={siteOptions}
					onChange={(value) => {
						setPutujmoSite(value.length > 0 ? value[0] : PutujmoSite.Serbia)
					}}
					getItemId={(item: any) => item.id}
					getItemText={(item: any) => item.text}
					disabled={isRead}
				/>
				<MultiSelect
					label='Jezik'
					value={[language]}
					items={languageOptions}
					onChange={(value) => {
						setLanguage(value.length > 0 ? value[0] : LanguageEnum.Serbian)
					}}
					getItemId={(item: any) => item.id}
					getItemText={(item: any) => item.text}
					disabled={isRead}
				/>
				<Input
					label='Naziv'
					value={title}
					onChange={(value) => setTitle(value)}
					disabled={isRead}
				/>
				<Input
					label='Investitor'
					value={investor}
					onChange={(value) => setInvestor(value)}
					disabled={isRead}
				/>
			</div>
			<div className='flex-between-container'>
				<Input
					label='Cena od'
					value={price}
					onChange={(value) => setPrice(value)}
					disabled={isRead}
				/>
				<Input
					label='Telefon'
					value={phone}
					onChange={(value) => setPhone(value)}
					disabled={isRead}
				/>
				<Input
					label='Email'
					value={email}
					onChange={(value) => setEmail(value)}
					disabled={isRead}
				/>
				<Input
					label='Web sajt'
					value={website}
					onChange={(value) => setWebsite(value)}
					disabled={isRead}
				/>
			</div>
			<div className='flex-between-container'>
				<div style={{width: '60%'}}>
					<Input
						label='Url naslovne slike'
						value={imageUrl}
						onChange={(value) => setImageUrl(value)}
						disabled={isRead}
					/>
				</div>
				<div style={{width: '30%'}}>
					<Input
						label='Lokacija'
						value={location}
						onChange={(value) => setLocation(value)}
						disabled={isRead}
					/>
				</div>
			</div>		
			<TextEditor
				// content rerender component
				value={values?.content || ''}
				onChange={(value) => setContent(value)}
				disabled={isRead}
			/>
			<div className='flex-end-container'>
				{!isRead && (
					<Button
						type='submit'
						value='Prihvati'
						disabled={
							!putujmoSite || 
							!title || 
							!language
						}
					/>
				)}
				{!isRead && (
					<Button
						type='button'
						value='Odustani'
						onClick={onCancel}
						outline
					/>
				)}
				{isRead && (
					<Button
						type='button'
						value='Izmeni'
						onClick={goUpdateCallback}
					/>
				)}
			</div>
		</form>
	)
}