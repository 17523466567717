import { useCallback, useMemo } from 'react'
import Select, { MultiValue, SingleValue } from 'react-select'

export type Props = {
	label?: string
	value?: any[]
	onChange(value: any[]): void
	disabled?: boolean
	items: any[]
	getItemId(item: any): any
	getItemText(item: any): string
	multiselect?: boolean
	isClearable?: boolean
}

export const MultiSelect = ({
	label,
	value, 
	onChange,
	disabled,
	items, 
	getItemId, 
	getItemText, 
	multiselect,
	isClearable
}: Props) => {

	const itemsToOptionArray = useMemo(
		() => {
			return items.map((item) => {
				return {
					value: getItemId(item),
					label: getItemText(item)
				}
			})
		},
		[items, getItemId, getItemText]
	)

	const valuesToOptionArray = useMemo(
		() => {
			return itemsToOptionArray.filter(option => value?.includes(option.value));
		},
		[value, itemsToOptionArray]
	)

	const changeCallback = useCallback(
		(changedValue: SingleValue<{value: number;label: string;}> | MultiValue<{value: number;label: string;}>) => {
			let newValue: number[] = []
			if (multiselect) {
				let multiChangedValue = changedValue as MultiValue<{value: number;label: string;}>;
				newValue = multiChangedValue.map(x => x.value);
			} else {
				let singleChangedValue = changedValue as SingleValue<{value: number;label: string;}>;
				if (singleChangedValue) {
					newValue = [singleChangedValue?.value]
				}
			}
			onChange(newValue);
		},
		[onChange, multiselect]
	);

	return (
		<div>
			{label && (
				<div className='label-input'>
					<label >{label}</label>
				</div>
			)}
			<Select
				classNames={{
					control: (state) => state.isDisabled ? 'custom-select disabled' : 'custom-select'
				}}
				styles={{
					menu: provided => ({ ...provided, zIndex: 9999 })
				}}
				options={itemsToOptionArray}
				value={valuesToOptionArray}
				onChange={changeCallback}
				isMulti={multiselect}
				isDisabled={disabled}
				placeholder='Izaberi...'
				noOptionsMessage={() => 'Nema rezultata'}
				isClearable={isClearable}
			/>
		</div>
	)
}