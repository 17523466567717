import { TabType } from '../../components/Tabs/Tabs';
import { AuthenticityCategoryRouter } from './Routers/AuthenticityCategoryRouter';
import { BlogCategoryRouter } from './Routers/BlogCategoryRouter';

export enum CategoriesSubroutes {
	Blog = 'blog-category',
	Authenticity = 'authenticity-category'
}

export const categoriesTabs: TabType[] = [
	{
		id: '1',
		title: 'Blog',
		component: BlogCategoryRouter,
		route: CategoriesSubroutes.Blog
	},
	{
		id: '2',
		title: 'Autentičnosti zemlje',
		component: AuthenticityCategoryRouter,
		route: CategoriesSubroutes.Authenticity
	},
];


