import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { ListComponentProps } from '../../Routers/CrudRouter';
import { useTableColumnsMemo } from './tableColumns';
import { tryCatchJsonByAction } from '../../../utils/fetchUtils';
import { TableButtons } from '../../../components/Table/TableButtons';
import { Table } from '../../../components/Table/Table';
import { CategoryResponse, PutujmoSite } from '../../../swagger/putujmoAdminService';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { MultiSelect } from '../../../components/MultiSelect/MultiSelect';
import { LanguageEnum, languageOptions, siteOptions } from '../../../utils/languageUtils';
import { deleteCategoryAction, getAllCategoriesAction } from '../../../features/categories/action';

export const CategoryTable = ({dataChangedTopic, publishDataChanged, additionalData}: ListComponentProps) => {
	const [putujmoSite, setPutujmoSite] = useState(PutujmoSite.Serbia);
	const [language, setLanguage] = useState(LanguageEnum.Serbian);
	const [selectedId, setSelectedId] = useState<string | number | undefined>(undefined);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<CategoryResponse[]>([]);

	const columns = useTableColumnsMemo();

	const fetchDataCallback = useCallback(
		async () => {
			setLoading(true);
			const bindedAction = getAllCategoriesAction.bind(null, putujmoSite, additionalData.articleType, language)
			var getResponse = await tryCatchJsonByAction(bindedAction);
			if (getResponse.success) {
				const items = getResponse.response || [];
				setData(items);
			}
			setLoading(false);
		},
		[putujmoSite, language, additionalData]
	)

	useEffect(
		() => {
			fetchDataCallback();
		},
		[fetchDataCallback]
	)

	useEffect(
		() => {
			const subscription = dataChangedTopic?.subscribe(fetchDataCallback);
			return () => {
				subscription?.unsubscribe();
			}
		},
		[fetchDataCallback, dataChangedTopic]
	)

	const deleteCallback = useCallback(
		async (id: number) => {
			const bindedAction = deleteCategoryAction.bind(null, id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				toast.success('Element je uspešno obrisan');
				setSelectedId(undefined);
				publishDataChanged();
			}
		},
		[publishDataChanged]
	)

	return (
		<div>
			<div className='flex-start-container'>
				<div className='m5'>
					<MultiSelect
						value={[putujmoSite]}
						items={siteOptions}
						onChange={(value) => {
							setPutujmoSite(value.length > 0 ? value[0] : PutujmoSite.Serbia)
						}}
						getItemId={(item: any) => item.id}
						getItemText={(item: any) => item.text}
					/>
				</div>
				<div className='m5'>
					<MultiSelect
						value={[language]}
						items={languageOptions}
						onChange={(value) => {
							setLanguage(value.length > 0 ? value[0] : LanguageEnum.Serbian)
						}}
						getItemId={(item: any) => item.id}
						getItemText={(item: any) => item.text}
					/>
				</div>
			</div>
			<TableButtons
				selectedId={selectedId}
				onDelete={deleteCallback}
				isViewable
			/>
			<Table
				columns={columns}
				data={data}
				onSelectedChange={setSelectedId}
			/>
			{loading && <LoadingSpinner />}
		</div>
	)
}
