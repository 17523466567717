import { propertyOf } from '../../utils/propertyOf'
import { CrudRouter, ParamType } from '../Routers/CrudRouter'
import { ReadRegistrationConfirm } from './Crud/ReadRegistrationConfirm'
import { RegistrationConfirmTable } from './Table/RegistrationConfirmTable'

export const RegistrationConfirmRouter = () => {
	return (
		<CrudRouter
			ListComponent={RegistrationConfirmTable}
			ReadComponent={ReadRegistrationConfirm}
			paramName={propertyOf<ParamType>('id')}
		/>
	)
}