import { Switch, Route, Redirect } from 'react-router-dom'
import { useCallback } from 'react'
import { Login } from '../Login/Login'
import { RouteEnum, routes } from '../../settings/routes'
import { getToken } from '../../utils/tokenUtils'
import { AppContainer } from '../AppContainer/AppContainer'

export const AccessRouter = () => {
	const isLoggedInCallback= useCallback(
		() => !!getToken(),
		[]
	)

	const renderLoginRouteCallback = useCallback(
		() =>  !isLoggedInCallback() ? <Login /> : <Redirect to={routes[RouteEnum.Home]} />,
		[isLoggedInCallback]
	)

	
	const renderAppRouteCallback = useCallback(
		() =>  isLoggedInCallback() ? <AppContainer /> : <Redirect to={routes[RouteEnum.Login]} />,
		[isLoggedInCallback]
	)
	
	return (
		<Switch>
			<Route path={routes[RouteEnum.Login]} render={renderLoginRouteCallback} />
			<Route path={routes[RouteEnum.Home]} render={renderAppRouteCallback} />
		</Switch>
	)
}
