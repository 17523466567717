import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { ListComponentProps } from '../../Routers/CrudRouter';
import { useTableColumnsMemo } from './tableColumns';
import { deleteUserAction, getAllUsersAction } from '../../../features/users/action';
import { tryCatchJsonByAction } from '../../../utils/fetchUtils';
import { TableButtons } from '../../../components/Table/TableButtons';
import { Table } from '../../../components/Table/Table';
import { ManagementUserResponse } from '../../../swagger/putujmoAdminService';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { propertyOf } from '../../../utils/propertyOf';

export const UsersTable = ({dataChangedTopic, publishDataChanged}: ListComponentProps) => {
	const [selectedEmail, setSelectedEmail] = useState<string | number | undefined>(undefined);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<ManagementUserResponse[]>([]);

	const columns = useTableColumnsMemo();

	const fetchDataCallback = useCallback(
		async () => {
			setLoading(true);
			var getResponse = await tryCatchJsonByAction(getAllUsersAction);
			if (getResponse.success) {
				const items = getResponse.response || [];
				setData(items);
			}
			setLoading(false);
		},
		[]
	)

	useEffect(
		() => {
			fetchDataCallback();
		},
		[fetchDataCallback]
	)

	useEffect(
		() => {
			const subscription = dataChangedTopic?.subscribe(fetchDataCallback);
			return () => {
				subscription?.unsubscribe();
			}
		},
		[fetchDataCallback, dataChangedTopic]
	)

	const deleteCallback = useCallback(
		async (email: string) => {
			const bindedAction = deleteUserAction.bind(null, email);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				toast.success('Element je uspešno obrisan');
				setSelectedEmail(undefined);
				publishDataChanged();
			}
		},
		[publishDataChanged]
	)

	return (
		<div>
			<TableButtons
				selectedId={selectedEmail}
				onDelete={deleteCallback}
				isViewable
				isChangeable={false}
			/>
			<Table
				columns={columns}
				data={data}
				onSelectedChange={setSelectedEmail}
				idPropName={propertyOf<ManagementUserResponse>('userEmail')}
			/>
			{loading && <LoadingSpinner />}
		</div>
	)
}
