import { Provider } from 'react-redux';
import { store } from '../../redux/store';
import { ContentRouter } from '../Routers/ContentRouter'
import { Sidebar } from '../Sidebar/Sidebar';

export const AppContainer = () => {
	return (
		<Provider store={store}>
			<div className='app-container'>
				<Sidebar />
				<ContentRouter />
			</div>
		</Provider>
	)
}