import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ParamType, UpdateComponentProps } from '../../Routers/CrudRouter';
import { CategoryResponse, UpdateCategoryRequest } from '../../../swagger/putujmoAdminService';
import { useGoBackFromUpdate } from '../../../utils/crudHooks';
import { getCategoryAction, updateCategoryAction } from '../../../features/categories/action';
import { tryCatchJsonByAction } from '../../../utils/fetchUtils';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { Dialog } from '../../../components/Dialog/Dialog';
import { CrudEnum } from '../../../settings/routes';
import { CategoryForm } from '../CategoryForm';

export const UpdateCategory = ({ publishDataChanged, additionalData }: UpdateComponentProps) => {
	const params: ParamType = useParams();
	const id = parseInt(params.id as string);

	const [loading, setLoading] = useState(true);
	const [data, setData] = useState(new CategoryResponse());
	const goBackFromUpdate = useGoBackFromUpdate();

	const fetchDataCallback = useCallback(
		async () => {
			setLoading(true);
			const bindedAction = getCategoryAction.bind(null, id)
			var getResponse = await tryCatchJsonByAction(bindedAction);
			if (getResponse.success) {
				setData(getResponse.response || new CategoryResponse())
			}
			setLoading(false);
		},
		[id]
	)

	useEffect(
		() => {
			fetchDataCallback();
		},
		[fetchDataCallback]
	)

	const handleSave = useCallback(
		async (newValues: UpdateCategoryRequest) => {
			setLoading(true);
			const model = new UpdateCategoryRequest({
				...newValues,
				id
			})
			const bindedAction = updateCategoryAction.bind(null, model);
			const response = await tryCatchJsonByAction(bindedAction);
			setLoading(false);

			if (response.success) {
				toast.success('Element je uspešno izmenjen.');
				publishDataChanged();
				goBackFromUpdate();
			} 
		},
		[publishDataChanged, goBackFromUpdate, id]
	);

	if (loading) {
		return <LoadingSpinner />
	}

	return (
		<Dialog
			title='Izmena elementa'
			onClose={goBackFromUpdate}
			open
		>
			<CategoryForm
				values={data}
				onSubmit={handleSave}
				onCancel={goBackFromUpdate}
				crudEnum={CrudEnum.Update}
				articleType={additionalData.articleType}
			/>
		</Dialog>
	)
}
