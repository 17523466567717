import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ParamType, ReadComponentProps } from '../../Routers/CrudRouter';
import { useGoBackFromRead } from '../../../utils/crudHooks';
import { tryCatchJsonByAction } from '../../../utils/fetchUtils';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { Dialog } from '../../../components/Dialog/Dialog';
import { RegistrationState, RegistrationUserResponse } from '../../../swagger/putujmoAdminService';
import { RegistrationConfirmForm } from '../RegistrationConfirmForm';
import { getRegisteredUserAction, updateRegisteredUserStatusAction } from '../action';
import { toast } from 'react-toastify';

export const ReadRegistrationConfirm = ({publishDataChanged}: ReadComponentProps) => {
	const params: ParamType = useParams();
	const id = parseInt(params.id as string);

	const [loading, setLoading] = useState(true);
	const [data, setData] = useState(new RegistrationUserResponse());
	const goBackFromRead = useGoBackFromRead();

	const fetchDataCallback = useCallback(
		async () => {
			setLoading(true);
			const bindedAction = getRegisteredUserAction.bind(null, id)
			var getResponse = await tryCatchJsonByAction(bindedAction);
			if (getResponse.success) {
				setData(getResponse.response || new RegistrationUserResponse())
			}
			setLoading(false);
		},
		[id]
	)

	const handleSave = useCallback(
		async (newState: RegistrationState) => {
			setLoading(true);
			const bindedAction = updateRegisteredUserStatusAction.bind(null, id, newState);
			const response = await tryCatchJsonByAction(bindedAction);
			setLoading(false);

			if (response.success) {
				toast.success('Element je uspešno izmenjen.');
				publishDataChanged();
				goBackFromRead();
			} 
		},
		[publishDataChanged, goBackFromRead, id]
	);

	useEffect(
		() => {
			fetchDataCallback();
		},
		[fetchDataCallback]
	)


	if (loading) {
		return <LoadingSpinner />
	}

	return (
		<Dialog
			title='Detalji o registraciji'
			onClose={goBackFromRead}
			size='xxxlarge'
			open
		>
			<RegistrationConfirmForm
				values={data}
				onCancel={goBackFromRead}
				onSubmit={handleSave}
			/>
		</Dialog>
	)
}
