import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ParamType } from '../../Routers/CrudRouter';
import { ManagementUserResponse } from '../../../swagger/putujmoAdminService';
import { useGoBackFromRead } from '../../../utils/crudHooks';
import { getUserAction } from '../../../features/users/action';
import { tryCatchJsonByAction } from '../../../utils/fetchUtils';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { Dialog } from '../../../components/Dialog/Dialog';
import { CrudEnum } from '../../../settings/routes';
import { UserForm } from '../UserForm';

export const ReadUser = () => {
	const params: ParamType = useParams();
	const email = params.id as string;

	const [loading, setLoading] = useState(true);
	const [data, setData] = useState(new ManagementUserResponse());
	const goBackFromRead = useGoBackFromRead();

	const fetchDataCallback = useCallback(
		async () => {
			setLoading(true);
			const bindedAction = getUserAction.bind(null, email)
			var getResponse = await tryCatchJsonByAction(bindedAction);
			if (getResponse.success) {
				setData(getResponse.response || new ManagementUserResponse())
			}
			setLoading(false);
		},
		[email]
	)

	useEffect(
		() => {
			fetchDataCallback();
		},
		[fetchDataCallback]
	)


	if (loading) {
		return <LoadingSpinner />
	}

	return (
		<Dialog
			title='Detalji'
			onClose={goBackFromRead}
			size='large'
			open
		>
			<UserForm
				values={data}
				crudEnum={CrudEnum.Read}
			/>
		</Dialog>
	)
}
