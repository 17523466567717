import { ArticleType } from '../../../swagger/putujmoAdminService'
import { propertyOf } from '../../../utils/propertyOf'
import { CrudRouter, ParamType } from '../../Routers/CrudRouter'
import { CreateCategory } from '../Crud/CreateCategory'
import { ReadCategory } from '../Crud/ReadCategory'
import { UpdateCategory } from '../Crud/UpdateCategory'
import { CategoryTable } from '../Table/CategoryTable'

export const AuthenticityCategoryRouter = () => {
	return (
		<CrudRouter
			ListComponent={CategoryTable}
			ReadComponent={ReadCategory}
			UpdateComponent={UpdateCategory}
			CreateComponent={CreateCategory}
			additionalData={{articleType: ArticleType.Authenticity}}
			paramName={propertyOf<ParamType>('id')}
		/>
	)
}