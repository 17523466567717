import { useCallback } from 'react';
import { CrudEnum } from '../settings/routes';
import { useGoBackAndPushCallback, useGoBackCallback } from './historyHooks';

const getLevelForGoBackFromCrud = (crud: CrudEnum, hasPrefix?: boolean, hasTabs?: boolean) => {
	let level = crud === CrudEnum.Create ? 1 : 2;

	hasPrefix && level++;
	hasTabs && level++;

	return level;
}

export const useGoBackFromCrud = (crud: CrudEnum, hasPrefix?: boolean, hasTabs?: boolean) => {
	const goBackCallback = useGoBackCallback();

	return useCallback(
		() => {
			const level = getLevelForGoBackFromCrud(crud, hasPrefix, hasTabs);
			goBackCallback(level)
		},
		[goBackCallback, crud, hasPrefix, hasTabs]
	)
}

export const useGoBackFromCreate = (hasPrefix?: boolean, hasTabs?: boolean) => {
	return useGoBackFromCrud(CrudEnum.Create, hasPrefix, hasTabs);
}

export const useGoBackFromUpdate = (hasPrefix?: boolean, hasTabs?: boolean) => {
	return useGoBackFromCrud(CrudEnum.Update, hasPrefix, hasTabs);
}

export const useGoBackFromRead = (hasPrefix?: boolean, hasTabs?: boolean) => {
	return useGoBackFromCrud(CrudEnum.Read, hasPrefix, hasTabs);
}

export const useGoBackFromCrudAndPush = (crud: CrudEnum, hasPrefix?: boolean, hasTabs?: boolean) => {
	const goBackAndPushCallback = useGoBackAndPushCallback();

	return useCallback(
		(toPush: string) => {
			const level = getLevelForGoBackFromCrud(crud, hasPrefix, hasTabs);
			goBackAndPushCallback(level, toPush)
		},
		[goBackAndPushCallback, crud, hasPrefix, hasTabs]
	)
}
