import { useMemo } from 'react';
import { ManagementUserResponse } from '../../../swagger/putujmoAdminService';
import { propertyOf } from '../../../utils/propertyOf';
import { ColumnDefinition } from 'react-tabulator';

export const useTableColumnsMemo = () => {

	const columns: ColumnDefinition[] = useMemo(
		() => {
			return [
				{
					title:'Ime', 
					field: propertyOf<ManagementUserResponse>('firstName')
				},
				{
					title:'Prezime', 
					field: propertyOf<ManagementUserResponse>('lastName')
				},
				{
					title:'Email', 
					field: propertyOf<ManagementUserResponse>('userEmail')
				},
				{
					title:'Rola', 
					field: propertyOf<ManagementUserResponse>('role')
				},	
			]
		},
		[]
	)

	return columns;
}