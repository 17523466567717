import { useMemo } from 'react';
import { UserRoleEnum } from '../swagger/putujmoAdminService';

const ROLE = 'role';

export const setRole = (role: UserRoleEnum) => {
	localStorage.setItem(ROLE, role);
}

const getRole = () : UserRoleEnum | undefined => {
	const role = localStorage.getItem(ROLE);
	if (!role) {
		return ;
	}
	return role as UserRoleEnum
}

export const useIsLogedInUserInRoleMemo = (role: UserRoleEnum) => {

	const result = useMemo(
		() => {
			return getRole() === role;
		},
		[role]
	)

	return result;
}

export const useIsLogedInUserSuperAdminMemo = () => {
	return useIsLogedInUserInRoleMemo(UserRoleEnum.SuperAdmin);
}

export const useIsLogedInUserAdminMemo = () => {
	return useIsLogedInUserInRoleMemo(UserRoleEnum.Admin);
}

export const useIsLogedInUserOperatorMemo = () => {
	return useIsLogedInUserInRoleMemo(UserRoleEnum.Operator);
}

export const useIsLogedInUserSuperAdminOrAdminMemo = () => {
	const isUserSuperAdmin = useIsLogedInUserSuperAdminMemo();
	const isUSerAdmin = useIsLogedInUserAdminMemo();

	const result = useMemo(
		() => {
			return isUserSuperAdmin || isUSerAdmin;
		},
		[isUserSuperAdmin, isUSerAdmin]
	)
	return result;
}