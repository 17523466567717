import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../Button/Button';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { SimpleDialog } from '../Dialog/SimpleDialog/SimpleDialog';
import { CrudEnum } from '../../settings/routes';
import { createPath } from '../../utils/routeUtils';

type Props = {
	selectedId?: string | number
	onDelete?(id: string | number): Promise<void>
	additionalId?: string | number

	// additional state that is passed to CRU routes using history state
	// INFO: state is not good to use, because page reload (or URL copy/paste) will not work
	// if you must use it, please make sure that component works after page reload
	historyState?: any
	prefix?: string

	AdditionalButton?: JSX.Element

	isCreateable?: boolean
	isViewable?: boolean
	isChangeable?: boolean
	isDeleteable?: boolean

	disabled?: boolean

	labels?: {
		create?: string
		view?: string
		change?: string
		delete?: string
	}

	specificActionDisabled?: {
		create?: boolean
		view?: boolean
		change?: boolean
		delete?: boolean
	}
	deleteDialogTitle?: string
	deleteDialogText?: string
};

// When CRU button is clicked it will point to new route: /create, /read/:id, /update/:id
// And when D button is clicked, it will call prop.onDelete

export const TableButtons = ({
	selectedId,
	onDelete,
	additionalId,
	historyState,
	prefix = '',
	isCreateable = true,
	isViewable = false,
	isChangeable = true,
	isDeleteable = true,
	disabled,
	labels,
	specificActionDisabled,
	AdditionalButton,
	deleteDialogTitle = 'Brisanje',
	deleteDialogText = 'Da liste sigurni da želite da obrišete izabrani element?'
}: Props) => {
	let history = useHistory();

	const [deleting, setDeleting] = useState(false);

	const idParamsMemo = useMemo(
		() => {
			let result = selectedId;
			if (additionalId) {
				result += `/${additionalId}`;
			}
			return result;
		},
		[selectedId, additionalId]
	)

	// INFO: if pathname ends with /, it gets double // which is not good
	const onCreate = useCallback(
		() => history.push(createPath(`/${history.location.pathname}/${prefix}/${CrudEnum.Create}`), historyState),
		[history, historyState, prefix]
	)

	const onView = useCallback(
		() => history.push(createPath(`${history.location.pathname}/${prefix}/${CrudEnum.Read}/${idParamsMemo}`), historyState),
		[history, idParamsMemo, historyState, prefix]
	)

	const onChange = useCallback(
		() => history.push(createPath(`${history.location.pathname}/${prefix}/${CrudEnum.Update}/${idParamsMemo}`), historyState),
		[history, idParamsMemo, historyState, prefix]
	)

	const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);

	const openConfirmDeleteModal = useCallback(
		() => setIsConfirmDeleteModalOpen(true),
		[]
	);

	const closeConfirmDeleteModal = useCallback(
		() => setIsConfirmDeleteModalOpen(false),
		[]
	)

	const onDeleteConfirmed = useCallback(
		async () => {
			setDeleting(true);
			closeConfirmDeleteModal();
			if (selectedId) {
				onDelete && await onDelete(selectedId);
			}

			setDeleting(false);
		},
		[closeConfirmDeleteModal, selectedId, onDelete]
	);

	return (
		<>
			<SimpleDialog
				open={isConfirmDeleteModalOpen}
				title={deleteDialogTitle}
				message={deleteDialogText}
				onConfirm={onDeleteConfirmed}
				onCancel={closeConfirmDeleteModal}
			/>
			<div>
				{isCreateable &&
					<Button
						value={labels?.create || 'Dodaj'}
						onClick={onCreate}
						disabled={disabled || specificActionDisabled?.create}
					/>
				}
				{isViewable &&
					<Button
						value={labels?.view || 'Detalji'}
						disabled={!selectedId || disabled || specificActionDisabled?.view}
						onClick={onView}
					/>
				}
				{isChangeable &&
					<Button
						value={labels?.change || 'Izmeni'}
						disabled={!selectedId || disabled || specificActionDisabled?.change}
						onClick={onChange}
					/>
				}
				{isDeleteable &&
					<Button
						value={labels?.delete || 'Obriši'}
						disabled={!selectedId || disabled || specificActionDisabled?.delete}
						onClick={openConfirmDeleteModal}
						outline
					/>
				}
				{AdditionalButton && (
					AdditionalButton
				)}
				{deleting && <LoadingSpinner />}
			</div>
		</>
	);
};