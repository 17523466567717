import { TabType } from '../../components/Tabs/Tabs';
import { ActivePhobsPropertiesRouter } from './Routers/ActivePhobsPropertiesRouter';
import { WithoutPricesPhobsPropertiesRouter } from './Routers/WithoutPricesPhobsPropertiesRouter';

export enum PhobsPropertiesSubroutes {
	Active = 'active',
	WithoutPrices = 'without-prices'
}

export const phobsPropertiesTabs: TabType[] = [
	{
		id: '1',
		title: 'Aktivni',
		component: ActivePhobsPropertiesRouter,
		route: PhobsPropertiesSubroutes.Active
	},
	{
		id: '2',
		title: 'Bez cena',
		component: WithoutPricesPhobsPropertiesRouter,
		route: PhobsPropertiesSubroutes.WithoutPrices
	},
];


