export const LoadingSpinner = () => {
	return (
		<div className='preloader'>
			<div className='loading-spinner'>
				<div className='lds-ring'>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		</div>
	)
}